.pro-access-info {
    background-color: var(--color-secondary-light);
    padding: 15px 11px 6px 11px;
}

.pro-access-info .icon {
    font-size: 15px;
}

.js-cart-dropdown__container .pro-access-info {
    padding: 15px 9px 12px 11px;
    line-height: 20px;
}