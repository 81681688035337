.js-ajax-flyout {
    padding: 0 !important;
}

.js-ajax-flyout__inner {
    position: relative;
    max-width: none;

    margin: 0;
}

.js-ajax-flyout__loading-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    pointer-events: none;
}

.js-ajax-flyout__wrapper {
    min-height: 100%;

    transform: translateX(100%);
    transition: cubic-bezier(.3,.46,.45,.94) .5s transform;
}

.js-ajax-flyout__content-wrapper {
    border: 0;
    max-width: 600px;
}

.js-ajax-flyout__header {
    padding: 30px 30px 0 20px;
    border-bottom: 0;

    @media screen and (min-width: 768px) {
        padding-top: 20px;
        padding-left: 40px;
    }
}

.js-ajax-flyout__close {
    font-size: 14px;

    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
}

.js-ajax-flyout__body {
    padding: 0 30px 50px 20px;

    @media screen and (min-width: 768px) {
        padding-left: 40px;
    }
}

.js-ajax-flyout .js-ajax-flyout__headline {
    position: relative;

    margin-top: 30px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid transparent;
}

.js-ajax-flyout__headline::after {
    content: '';

    width: 105px;
    border-bottom: 1px solid var(--color-black-50);

    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    pointer-events: none;
}

.js-ajax-flyout__pretext {
    margin-bottom: 50px;
}

.js-ajax-flyout__pretext:last-child {
    margin-bottom: 0;
}

.js-ajax-flyout__image-wrapper {
    margin-bottom: 10px;
}

.js-ajax-flyout__content-block {
    margin-bottom: 60px;
}

.js-ajax-flyout__content-block--spacing-small {
    margin-bottom: 45px;
}

.js-ajax-flyout__content-block--spacing-big {
    margin-bottom: 100px;
}

.js-ajax-flyout__content-block:last-child {
    margin-bottom: 0;
}

/* Loaded state */
.js-ajax-flyout--loaded .js-ajax-flyout__wrapper {
    transform: translateX(0);
}
