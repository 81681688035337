.column-collapse-icon-circle{
    width: calc(24rem / 16);
    height: calc(24rem / 16);
    border-color: var(--color-text-grey) !important;
    color: var(--color-text-grey);
}

.column-collapse-icon{
    font-size: calc(8rem / 16);
    transition-duration: .25s;
    transform: rotate(180deg);
}

.collapse.show + .column-collapse-icon__container .column-collapse-icon{
    transform: rotate(0);
}

.column-collapse-headline-icon{
    font-size: calc(150rem / 16);
    color: var(--color-text-grey-on-grey)
}

.column-collapse ul{
    padding-left: 0;
}

.column-collapse li{
    list-style: none;
    padding-left: 2rem;
    position: relative;
    margin-bottom: .375rem;
}

.column-collapse li:before{
    font-family: "iconfont";
    content: "\EA20";
    font-size: calc(15rem / 16);
    position: absolute;
    left:.125rem;
    top: 0;
}