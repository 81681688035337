.product-grid__filter-collapse-target{
    position: relative;
    max-height: 250px;
}

.product-grid__filter-collapse-target--no-scrollbar {
    @media screen and (max-width: 767px) {
        max-height: none;
    }
}

button.product-grid__filter-collapse{
    padding-left: 0;
    text-align: left;
}
