li.nav-item a.nav-link.collection-tab__nav-link {/*all selectors needed for specificity*/
    color: var(--color-default);
    background: #fff;
    border: 0;
    font-size: 1.2rem;
    line-height: 1;
    position: relative;
}

li.nav-item a.nav-link.collection-tab__nav-link.active {/*all selectors needed for specificity*/
    font-family: var(--font-default);
}

li.nav-item a.nav-link.collection-tab__nav-link.active:after {/*all selectors needed for specificity*/
    background-color: var(--color-border-grey);
    display: block;
    height: 1px;
    width: 70px;
    content: '';
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}