.headline-background {
    @media screen and (min-width: 768px){
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.headline-background__box {
    @media screen and (min-width: 1200px){
        width: 50%;
    }
}

.bg-white--headline-background {
    background-color: rgba(255,255,255,.9);
}

.headline-background__headline {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(calc(-100%));

    @media screen and (min-width: 768px){
        position: static;
        transform: none;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        background-color: rgba(255,255,255,.9);
    }
}

.headline-background__embed:before {
    padding-top: 56.25%;
    @media screen and (min-width: 992px){
        padding-top: 25%;
    }
}

.headline-background__headline:after{
    @media screen and (max-width: 767px) {
        display: none;
    }
}