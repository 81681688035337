.teams__member-image{
    position: relative;
}

.teams__member-image:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    background-color: #000;
}
.teams__member-image:hover:after{
    opacity: .15;
}

.teams__member-zoomicon{
    position: absolute;
    top: calc(50% - .5em);
    left: calc(50% - .5em);
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    color: #fff;
    font-size: 1.5em;
    z-index: 1;
}

.teams__member-image:hover .teams__member-zoomicon{
    opacity: 1;
}