.outfit-teaser__btns{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.outfit-teaser .custom-spacing{
    padding-left: 0 !important;
    padding-right: 0 !important;
}