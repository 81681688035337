.teaser-object{
    background-color: var(--color-background-grey);
}

.teaser-object:hover{
    box-shadow: 0px 0px 10px 3px rgba(246,246,247,0.75);
}

.teaser-object--icons{
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 1;
}

.teaser-object__image-label {
    position: absolute;
    top: 0px;
    z-index: 1;
    margin-top: calc(4rem /16);
    padding-left: 15px;
    padding-right: 5px;
    line-height: calc(22rem /16);
    font-size: calc(13rem /16);
    color: #fff;

    @media screen and (min-width: 768px) {
        margin-top: calc(10rem /16);
    }
}

.teaser-object__image-label--left {
    left: 0;
    margin-left: calc(4rem/16);
    @media screen and (min-width: 768px) {
        margin-left: calc(14rem/16);
    }
}

.teaser-object__image-label--right {
    right: 0;
    margin-right: 0;
    @media screen and (min-width: 768px) {
        margin-right: calc(10rem/16);
    }
    display: flex;
    flex-direction: column;
}
.teaser-grid__text {
    margin-bottom: 0;
}