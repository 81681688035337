.load-container--sticky.load-container--new-loading-icon {
    margin-top: 100px;
}

.header__dropdown .load-container--new-loading-icon .load-container__icon,
.load-container--new-loading-icon .load-container__icon {
    display: block;
}

.header__dropdown .load-container--new-loading-icon .load-container__icon,
.load-container--new-loading-icon .load-container__icon,
.load-container--new-loading-icon {
    width: 150px;
    height: 150px;
}


/* PDP exception */
.pds__product-slider-main .product-grid__loading .load-container {
    justify-content: center;
    width: 100%;

    left: auto;
    transform: translateY(-50%);
}

.js-pds__product-slider-main.slick-initialized + .product-grid__loading {
    display: none;
}
