.embed-responsive-grid-teaser-small-25{
&::before {
     padding-top: 137.89004%;
        @media screen and (max-width: 767px) {
            display:none;
        }
    }
}
.embed-responsive-grid-teaser-small-25 > .embed-responsive-item{
    @media screen and (max-width: 767px) {
        position: relative;
        width: 100%;
        height: auto;
    }
}

.embed-responsive-banner:before {
    padding-top: 56.25%;
    @media screen and (min-width: 768px){
        padding-top: 27.22222%;
    }
}

.embed-responsive-hero-content{
    @media screen and (max-width: 767px) {
        overflow: visible;
    }
    &::before{
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}
.embed-responsive-hero-content .embed-responsive{
    @media screen and (max-width: 767px) {
        position: relative;
        height: auto;
        width: auto;
    }
}


@media screen and (max-width: 767px) {
    .embed-responsive.embed-responsive-1by1-xs:before {
        padding-top: 100%;
    }
}