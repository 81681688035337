.account-dropdown select{
    border: 0;
    background-color: var(--color-background-grey);
}

.ovx__account-tab-nav::after {
    content: '';

    position: relative;

    display: block;
    width: 100%;

    border-top: 1px solid #343a40;

    order: 9999;

    z-index: 1;
}
 
.ovx__account-tab-nav .nav-tabs {
    position: relative;
    z-index: 2;
}

.ovx__account-tab-nav .nav-tabs::after {
    @media screen and (max-width: 767px) {
        content: none;
    }
}

.ovx__account-tab-nav .nav-tabs .nav-item .nav-link {
    color: var(--color-text-grey-on-grey);
    position: relative;

    padding: calc(14rem / 16) calc(26rem / 16) calc(11rem / 16);

    border: 1px solid var(--color-background-grey);
    border-bottom: 0;

    background-color: var(--color-background-grey);
}

.ovx__account-tab-nav .nav-tabs--line .nav-item > a::before {
    content: '';

    position: absolute;
    top: 100%;
    left: 0;

    display: block;
    width: 100%;

    border-top: 1px solid var(--color-white);

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 2;
}

.ovx__account-tab-nav .nav-tabs--line .nav-item > a::after {
    display: none;
}

.ovx__account-tab-nav .nav-tabs .nav-item .nav-link.active {
    color: var(--color-text-default);
    font-family: inherit;

    border-color: #343a40;
    border-bottom-color: var(--color-white);

    background-color: var(--color-white);
}

.ovx__account-tab-nav .nav-tabs .nav-item .nav-link.active::before {
    opacity: 1;
    visibility: visible;
}
