.checkout-summary {
    margin-bottom: calc(28rem / 16);

    @media screen and (min-width: 786px) {
        max-width: calc(340rem / 16);
        margin-left: auto;
    }
}
.checkout-summary__total-title {
    font-size: calc(16rem / 16);
    line-height: calc(24/16);
    align-self: center;
    margin-bottom: 0;
}
.checkout-summary__total-price {
    margin-bottom: 0;
}

.checkout-summary--lg {
    @media screen and (min-width: 786px) {
        max-width: unset;
    }
}
.checkout-summary--lg .checkout-summary__total-title {
    font-size: calc(20rem / 16);
    line-height: calc(24/20);
}
.checkout-summary--lg .checkout-summary__total-price {
    font-size: calc(24rem / 16);
}
