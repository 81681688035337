body .video-js__playbtn:before,
body .video-js__pausebtn:before{
    font-family: iconfont;
    content: var(--icon-play-filled);
    font-size: calc(70rem / 16);
    @media screen and (max-width: 767px){
        font-size: 2.375rem;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
        font-size: 3.375rem;
    }
}

body .video-js__pausebtn:before{
    content: var(--icon-pause-button);
}

body .video-js__playbtn:after,
body .video-js__pausebtn:after{
    content: '';
    display: none;
}

body .video-js__playbtn,
body .video-js__playbtn:hover,
body .video-js__playbtn:focus,
body .video-js__playbtn:active ,
body .video-js__playbtn:before,
body .video-js__playbtn:hover:before,
body .video-js__playbtn:focus:before,
body .video-js__playbtn:active:before,
body .video-js__pausebtn,
body .video-js__pausebtn:hover,
body .video-js__pausebtn:focus,
body .video-js__pausebtn:active ,
body .video-js__pausebtn:before,
body .video-js__pausebtn:hover:before,
body .video-js__pausebtn:focus:before,
body .video-js__pausebtn:active:before {
    color: #ffffff;
    background-color: transparent;
    outline: none;
    border: none;
}
body .video-js__playbtn--primary,
body .video-js__playbtn--primary:hover,
body .video-js__playbtn--primary:focus,
body .video-js__playbtn--primary:active,
body .video-js__playbtn--primary:before,
body .video-js__playbtn--primary:hover:before,
body .video-js__playbtn--primary:focus:before,
body .video-js__playbtn--primary:active:before,
body .video-js__pausebtn--primary,
body .video-js__pausebtn--primary:hover,
body .video-js__pausebtn--primary:focus,
body .video-js__pausebtn--primary:active,
body .video-js__pausebtn--primary:before,
body .video-js__pausebtn--primary:hover:before,
body .video-js__pausebtn--primary:focus:before,
body .video-js__pausebtn--primary:active:before {
    color: var(--color-primary);
}
body .video-js__playbtn,
body .video-js__pausebtn {
    line-height: 1;
    height: auto;
    width: auto;
}

h1, h2, h3, .wysiwyg {
    & + .video-js__playbtn,
    & + * .video-js__playbtn {
        margin-top: calc(30rem / 16);
    }
}

.video-js--default-play-btn .vjs-big-play-button.vjs-big-play-button,
.video-js--default-pause-btn .vjs-big-pause-button.vjs-big-pause-button  {
    height: auto;
    width: auto;
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
}
.video-js--default-play-btn .vjs-big-play-button:before,
.video-js--default-pause-btn .vjs-big-pause-button:before  {
    font-family: iconfont;
    content: var(--icon-play-filled);
    font-size: calc(70rem / 16);
    color: #ffffff;
    @media screen and (max-width: 767px){
        font-size: 2.375rem;
    }
    @media screen and (min-width: 768px) and (max-width: 991px){
        font-size: 3.375rem;
    }
}

.video-js--default-play-btn .vjs-big-play-button .vjs-icon-placeholder,
.video-js--default-pause-btn .vjs-big-pause-button .vjs-icon-placeholder {
    display: none;
}
.video-js--default-play-btn--primary .vjs-big-play-button.vjs-big-play-button,
.video-js--default-play-btn--primary .vjs-big-play-button.vjs-big-play-button:before,
.video-js--default-pause-btn--primary .vjs-big-pause-button.vjs-big-pause-button,
.video-js--default-pause-btn--primary .vjs-big-pause-button.vjs-big-pause-button:before {
    color: var(--color-primary);
}

.video-js__playbtn.video-js__playbtn--sm:before,
.video-js__pausebtn.video-js__pausebtn--sm:before {
    font-size: calc(24rem / 16);
    margin-right: calc(5rem / 16);

    @media screen and (min-width: 768px){
        font-size: calc(30rem / 16);
        margin-right: calc(8rem / 16);
    }
}
