.customer-backend-collapse-icon:not(.collapsed) .icon-rotation{
    transform: rotate(0deg);
    transition-duration: 0.25s;
}

.customer-backend-collapse-icon .icon-rotation{
    transition-duration: 0.25s;
    font-size: calc(9rem / 16);
}

.customer-backend__tab a{
    padding-left: 0;
}

.customer-backend__formcontent{
    padding: 0 !important;
    margin: 0 !important;
}

.customer-backend__return-row:nth-child(2n){
    background-color: var(--color-background-grey);
}
