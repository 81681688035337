.raffle__container{
    background-color: #fff;
    transition-property: transform, opacity, background-color;
    box-shadow: 0 2px 24px 0 rgba(38,38,38,0.25);
}

.raffle__container--animating{
    background-color: var(--color-black-60);
}

.raffle__step{
    background-color: var(--color-background-grey)
}

.raffle__tabcontainer{
    background-color: var(--color-black-60);
    height: var(--raffle-nav-height);
    display: flex;
    @media screen and (max-width: 767px){
        background-color: var(--color-black-90);
    }
}

.raffle__tabs-close{
    background-color: var(--color-black-90);
    border-left: 1px solid #fff;
    color: #fff !important;
    height: var(--raffle-nav-height);
    width: var(--raffle-nav-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

.raffle__tabs-btn{
    color: #fff !important;
    height: var(--raffle-nav-height);
    width: var(--raffle-nav-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

.raffle__tabs-prev{
    border-right: 1px solid #fff;
}
.raffle__tabs-next{
    border-left: 1px solid #fff;
}

.raffle__tabs{
    flex: 1;

}

.raffle__tab-link{
    color: #fff !important;
    text-transform: uppercase;
}

.raffle__tabs:before {
    background: linear-gradient(to right, rgba(135,135,135,1) 0%,rgba(135,135,135,0) 100%);
}

.raffle__tabs:after {
    background: linear-gradient(to right, rgba(135,135,135,0) 0%,rgba(135,135,135,1) 100%);
}
.raffle__error-container ul{
    margin-top: 25px;
}

.raffle__tabs-btn{
    opacity: 1;
    visibility: visible;
    transition-duration: .33s;
    transition-property: opacity;
}
.raffle__tabs-btn--remove{
    opacity: 0;
    visibility: hidden;
}
.raffle__tabs-btn--inactive{
    background-color: var(--color-black-60);
}
.raffle__container-wrapper{
    @media screen and (max-width: 767px) {
        background-color: var(--color-background-grey)
    }
}

/* custom css before pimcore x */
.raffle__container .form-errors,
.raffle__container .floating-label {
    display:none;
}

.raffle__container #form_Row2_Column4 {
    margin-top: 10px;

}
.raffle__container #form_Row2_Column4 div.row:nth-child(2),
.raffle__container #form_Row2_Column4 div.row:nth-child(3) {
    margin-left:0; margin-top:25px;
}

.raffle__container .wysiwyg h2+:before {
    display:none;

}
.raffle__container .modal-footer {
    display:block;
    padding: 0.75rem 0;
    border:none;
}

@media screen and (max-width: 768px) {
    .raffle__container #form_Row2_Column4_Row24_Column26 {
        display:none;
    }
    a.product-rating {
        white-space:nowrap;
    }
}

.raffle__container .has-error .custom-checkbox__box {
    border-color: #dc3545;
}

.js-raffle__teaser .wysiwyg h2+p:before {
    background:none;
}