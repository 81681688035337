.alert-box {
    padding: 36px 52px;
    border: 1px solid;
    margin-bottom: 1.5rem;
    display: inline-block;
}

.alert-box--success {
    background-color: var(--color-success);
    border-color: var(--color-success);
}

.alert-box--success-light {
    background-color: var(--color-success-light);
    border-color: var(--color-success-light-border);
}

.alert-box--info {
    background-color: var(--color-info);
    border-color: var(--color-info);
}

.alert-box--warning {
    background-color: var(--color-warning);
    border-color: var(--color-warning);
}

.alert-box--info-blue {
    background-color: var(--color-info-blue);
    border-color: var(--color-info-blue);
}

.alert-box--danger {
    background-color: var(--color-danger);
    border-color: var(--color-danger);
}

