.teaser-slider-spacing{
    padding-top: calc(15rem / 16);
    padding-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(55rem / 16);
        padding-right: calc(30rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding-top: calc(80rem / 16);
        padding-bottom: calc(80rem / 16);
        padding-left: 0;
        padding-right: 0;
    }
}

.content-slider .slick-dots{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(50%);
    display: flex !important; /* override inline style */

    @media screen and (min-width: 768px) {
        transform: translateY(150%);
    }

    @media screen and (min-width: 1400px) {
        transform: translateY(200%);
    }
}

.content-slider.slick-slider li button{
    visibility: visible;
    font-size: 0;
    background-color: var(--color-text-grey-on-grey);
    border: 0;
    padding: 0;
    width: 8px;
    height: 8px;
    margin: 0 calc(4rem / 16);
}

.content-slider.slick-slider li.slick-active button{
    background-color: var(--color-text-default);
}

.content-slider.slick-slider .slick-prev{
    @media screen and (max-width: 768px){
        left: -20px;
    }
}

.content-slider.slick-slider .slick-next{
    @media screen and (max-width: 767px){
        right: -20px;
    }
}

.content-slider__text{
    display: flex;
    flex-direction: column;
    flex: auto;
}