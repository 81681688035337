th {
    font-family: var(--font-default-bold);
    font-weight: normal;
    text-transform: uppercase;
}

legend {
    font-size: calc(19rem/16);
    font-family: var(--font-default-heading);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size: 1.25rem;
    }
}

hr {
    border-top-color: var(--color-border-grey);
}

/* custom css before pimcore x */
@media screen and (min-width: 992px) and (max-width: 1450px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 96% !important;
    }
}