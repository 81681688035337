.cart-navigation-row {
    @media screen and (min-width: 768px) {
        margin-bottom: calc(28rem / 16);
    }
}
.cart-item__title {
    font-size: calc(20rem / 16);
    line-height: calc(24/20);
}
.cart-item__desc {
    margin-top: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(20rem / 16);
    }
}
.cart-item__category {
    color: var(--color-text-grey);
    text-transform: uppercase;
}

.back-link {
    font-family: var(--font-default-bold);
    font-weight: 400;
    font-size: calc(16rem / 16);
    text-transform: uppercase;
}
.back-link__icon {
    font-size: calc(10rem / 16) !important; /* needed to overwrite fz class */
    margin-top: calc(-2rem / 16);
}
.back-link__icon:before {
    content: var(--icon-ortovox_arrow-left);
}

.cart__font-md-strong {
    font-family: var(--font-default-bold);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        font-size: inherit;
        font-family: var(--font-default);
        font-weight: 400;
        text-transform: none;
    }
}