.custom-radio__box {
    border: calc(1rem/16) solid #c4c6c7;
}
.custom-radio__input:checked ~ .custom-radio__box {
    border: calc(1rem/16) solid var(--color-text-default);
}
.custom-radio__box:before{
    width: calc(8rem / 16);
    height: calc(8rem / 16);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}
.custom-radio__input:checked ~ .custom-radio__text{
    color: var(--color-primary);
}

.custom-radio--button .custom-radio__box-btn{
    border-color: var(--color-border-grey);
}

.custom-radio--button .custom-radio__input:disabled ~ .custom-radio__box-btn{
    background-color: var(--color-background-grey);
    color: var(--color-text-grey-on-grey);
    border-color: var(--color-border-grey);
}

.custom-radio--flex label {
    display: flex;
}
.custom-radio--flex .custom-radio__text {
    margin-right: auto;
}
.custom-radio--flex .radio-edit-link {
    top: 0;
}
.custom-radio--flex .custom-radio__box {
    flex-shrink: 0;
}