.anchor-menu{
    z-index: 999;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 10px 0 rgba(27,36,42,0.2);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;
    @media screen and (max-width: 991px) {
        bottom: .5rem;
        right:.5rem;
        top: auto;
        transform: translateX(0);
    }
}
.anchor-menu ul{
    @media screen and (max-width: 991px) {
        display: flex;
    }
}
.anchor-menu__item{
    margin-bottom: 1px;
    @media screen and (max-width: 991px) {
        margin-bottom: 0;
        margin-left: 1px;
    }
}

.anchor-menu__item a{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 75px;
    font-size: 1.5em;
    color: #fff;
    padding: .25rem;
    text-align: center;
    background-color: var(--color-primary);
}
.anchor-menu__item:last-child{
    margin-bottom: 0;
}

.anchor-menu__badge{
    position: absolute;
    top: calc(3rem / 16);
    right: .25em;
    border-radius: 100%;
    padding: calc(4rem / 16);
    font-size: .5em;
    color: var(--color-primary);
    box-shadow: 0 0 10px 0 rgba(27,36,42,0.2);
    min-width: calc(18rem / 16);
    height: calc(18rem / 16);
}

.anchor-menu__text{
    font-size: calc(12rem/16);
}