.form-group.has-error .form-control {
    background-image: unset;
}
.form-errors {
    font-size: calc(10rem / 16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
.form-errors ul li:before {
    padding: 0;
    background: unset;
    color: var(--color-danger);
}