.checkout-steps {
    text-transform: uppercase;
    padding-top: calc(65rem / 16);
    margin-bottom: calc(53rem / 16);

    @media screen and (max-width: 992px) {
        padding-top: calc(45rem / 16);
        margin-bottom: calc(40rem / 16);
    }
}
.checkout-steps .checkout-steps__item {
    color: var(--color-text-grey);
}
.checkout-steps .checkout-steps__item:before {
    width: calc(32rem / 16);
    height: calc(32rem / 16);
    top: calc(-5rem / 16);
    line-height: 1;
    padding: calc(10rem / 16) calc(2rem / 16) calc(8rem / 16);
    border: 0;
    background: var(--color-text-grey);
    color: #fff;

    @media screen and (min-width: 768px) {
        padding-top: calc(8rem / 16);
    }
}
.checkout-steps .checkout-steps__item+.checkout-steps__item:after {
    content: none;
}
.checkout-steps .checkout-steps__item--active {
    color: var(--color-primary);
}
.checkout-steps .checkout-steps__item--active:before {
    background: var(--color-primary);
}
.checkout-steps .checkout-steps__item+.checkout-steps__item {
    margin-left: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(75rem / 16);
    }
}
.checkout-steps .checkout-steps__item--complete {
    color: var(--color-primary);
}
.checkout-steps .checkout-steps__item--complete:before {
    color: var(--color-primary);
    background: var(--color-primary);
}
