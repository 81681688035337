.error-page{
    background-size: cover;
    position: relative;
    height: 850px;
    background-position: center bottom;
    @media screen and (max-width: 767px) {
        height: 500px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        height: 700px;
    }
}

.error_page__text{
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    width: 100%;
    z-index: 10;
}