.custom-checkbox__box{
    margin-top: 0;
}
.custom-checkbox__text {
    line-height: var(--line-height-body-text);
}
.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__box:before{
    color: #fff;
    z-index: 1;
}

.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked ~ .custom-checkbox__box:after {
    content: '';
    position: absolute;
    background-color: var(--color-primary);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: none;
}
.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked ~ .custom-checkbox__text{
    color: var(--color-primary);
}

.custom-checkbox--color{
    height: 24px;
    width: 24px;
    position: relative;
    @media screen and (max-width: 767px) {
        height: 32px;
        width: 32px;
    }
}

.custom-checkbox--color label{
    border-radius: 50%;
}

.custom-checkbox--color .custom-checkbox--color__active{
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    opacity: 0;
}
.custom-checkbox--color .custom-checkbox--color__active:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    border-radius: 50%;
}
.custom-checkbox--color input:checked ~ .custom-checkbox--color__active{
    opacity: 1;
}

.custom-checkbox--sport input:checked ~ .custom-checkbox--sport__img-wrapper {
    border-color: black !important;
}

.custom-checkbox--sport input:checked ~ .custom-checkbox--sport__label-text {
    font-family: var(--font-default-bold);
    font-weight: normal;
    text-transform: uppercase
}

.custom-checkbox__box:before{
    content: '';
}


.custom-checkbox__outer-box:before {
    border-color: var(--color-black-90);
}
.custom-checkbox--border .custom-checkbox__text{
    margin-bottom: -.3125rem;
}

.unzerUI.custom-checkbox.custom-checkbox label::before {
    display: none;
}

.unzerUI.custom-checkbox .custom-checkbox__text p {
    margin: 0;
}
