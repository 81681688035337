.header--checkout {
    height: calc(96rem / 16);
}
.header--checkout .metabar {
    background: #fff;
    border-bottom: 1px solid #DEDEDE;
}

.header__nav .nav-item, .header__nav .nav-item>a{
    text-align: left;
    align-items: flex-start;

    @media screen and (max-width: 991px){
        align-items: center;
    }
}

.header__nav .ovx__nav-item--no-default-divider.ovx__nav-item--no-default-divider > a::after {
    display: none;
}

.header__nav .ovx__nav-item--with-account-divider > a {
    margin-top: -2px;
}

.header__nav .ovx__nav-item--with-account-divider > a::before,
.header__nav .ovx__nav-item--with-account-divider.ovx__nav-item--with-account-divider > a::after {
    content: '';

    display: block;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    border-top: 1px solid #eee;

    z-index: 2;
}

.header__nav .ovx__nav-item--with-account-divider > a::before {
    right: auto;

    border-color: var(--color-white);
    border-width: 5px;
    margin-top: -2px;

    z-index: 2;
}

.header__nav .ovx__nav-item--with-account-divider > .header__submenu {
    z-index: 3;
}

.header__nav .ovx__nav-item--with-account-divider--bottom > a::before {
    display: none;
}

.header__nav .ovx__nav-item--with-account-divider.ovx__nav-item--with-account-divider--bottom > a::after {
    top: auto;
    bottom: 0;
}

.header__nav .nav-item.nav-item--small {
    @media (max-width: 991px) {
        height: 30px;
        min-height: 30px;
    }
}

.header__nav .nav-item .icon-ortovox-account::before {
    @media (max-width: 991px) {
        position: relative;
        top: 1px;
    }
}

.ovx__account-nav__container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    display: none;
    justify-content: flex-end;
    height: 0;

    overflow: visible;
    z-index: 1;

    @media screen and (min-width: 992px) {
        display: flex;
    }
}

.ovx__account-nav__nav-list {
    position: absolute;
    top: 0;
    right: 0;

    width: calc(300rem/16);
    max-width: 100%;

    margin-top: -1px;
    padding-bottom: calc(35rem/16);

    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease,visibility .3s ease;

    z-index: 1;
}

.ovx__account-nav__nav-list.show {
    opacity: 1;
    visibility: visible;
}

.ovx__account-nav__nav-list .nav-item {
    height: auto;
    min-height: 64px;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}

.ovx__account-nav__nav-list .nav-item > a {
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.ovx__account-nav__nav-list .nav-item,
.ovx__account-nav__nav-list .nav-item > a {
    align-items: center;
}

.ovx__account-nav__nav-list .nav-item > a::after{
    display: block;
    content: '';
    position: absolute;
    top: calc(100% - 2px);
    width: 48px;
    height: 1px;
    background-color: var(--color-border-grey);
    opacity: 1;
}
.ovx__account-nav__nav-list .nav-item.nav-item--decoration{
    height: 64px; /* fix for ios safari & chrome */
}

.ovx__account-nav__nav-list .nav-item.nav-item--decoration > a::after {
    left: 0;
}

.ovx__nav-item--with-account-divider > a {
    z-index: 0;
}
