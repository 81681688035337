.vertical-gutter--50px{
    margin-top: 50px;
}
.vertical-gutter--50px>.vertical-gutter__item{
    margin-top: 50px;
}
.vertical-gutter--25px{
    margin-top: 25px;
}
.vertical-gutter--25px>.vertical-gutter__item{
    margin-top: 25px;
}
