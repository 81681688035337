.rating__item {
    width: 100%;
    height: 6px;

    border-radius: 3px;
    margin-right: 4px;

    background: #eee;
}

.rating__item:last-child {
    margin-left: 0;
}

.rating__item--active {
    background: var(--color-secondary);
}

.rating__title {
    font-size: .8125rem;
    line-height: .8125rem;
}
