.form-group.has-error .form-control {
    background-image: unset;
}
.form-errors.form-errors {
    font-size: calc(10rem / 16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
.form-errors ul li:before {
    padding: 0;
    background: unset;
    color: var(--color-danger);
}

.error-list, .parsley-errors-list{
    padding-left: 0;
    list-style: none;
}

.error-list__message, .parsley-custom-error-message{
    content:'!';
    padding: .5rem;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    color: var(--color-danger);
    margin-right:.25rem;
}

.unzerUI.unzerUI.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

.unzerUI.input > .unzerInput.parsley-error,
.unzerUI.input > input.parsley-error,
.unzerUI label.parsley-error .custom-checkbox__box {
    border-color:var(--color-danger);
    box-shadow: none;
}

.js-paymentform .unzerUI.form .error.message,
.js-paymentform .unzerUI.form .success.message,
.js-paymentform .unzerUI.form .warning.message {
    min-height: auto;
}
