.sheep-stories{
    pointer-events: none;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 50vw;
    max-width: 750px;
    z-index: 1000;
    animation: sheep-animateIn .5s forwards;
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.sheep-stories__text{
    pointer-events: auto;
    position: absolute;
    bottom: 105%;
    right: 28%;
    max-width: 30%;
    background-color: #fff;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    padding: 0.5rem .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sheep-stories__text:after{
    content: '';
    position: absolute;
    bottom: -21px;
    height: 21px;
    width: 30px;
    right: 25%;
    z-index: 1;
    background-image: url("/static/img/ortovox/sheep-bubble.svg");
    background-repeat: no-repeat;
    background-position: top left;
}
.sheep-stories__close{
    pointer-events: auto;
    position: absolute;
    top: -16px;
    left: calc(100% - 16px);
    width: 32px;
    height: 32px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff !important;
}

@keyframes sheep-animateIn {
    0%{
        transform: translateY(100%)
    }
    100%{
        transform: translateY(16%)
    }
}

#sheep-stories__ground, #sheep-stories__sheeps{
    pointer-events: auto;
}