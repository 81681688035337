@font-face {
    font-family: "DINNextRoundedLTPro-Light";
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: local("DINNextRoundedLTPro-Light"),
    url("/static/fonts/DINNextRoundedLTPro-Light.woff2") format("woff2"),
    url("/static/fonts/DINNextRoundedLTPro-Light.woff") format("woff") ;
}

@font-face {
    font-family: "DINNextRoundedLTPro-Bold";
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: local("DINNextRoundedLTPro-Bold"),
    url("/static/fonts/DINNextRoundedLTPro-Bold.woff2") format("woff2"),
    url("/static/fonts/DINNextRoundedLTPro-Bold.woff") format("woff");
}
