@import "../../../js/libs/@elements/elements-job-bundle/styles/list.css";
@import "../../../js/libs/@elements/elements-job-bundle/styles/list-area.css";

:root {
    --ejb-list-border-color: var(--color-border-grey);
    --ejb-list-head-background-color: #fff;
    --ejb-list-cell-background-color-hover: var(--color-background-grey);
    --ejb-list-ara__form-group-label-color: var(--color-text-grey);
}

.ejb-list-area__title {
    margin-bottom: 0;
}

.ejb-list-ara__filter {
    margin: 0;
}

.ejb-list-ara__form-group {
    margin-bottom: calc(16rem /16);
    @media screen and (min-width: 768px) {
        margin: 0;
    }
}

.ejb-list-ara__form-group-label {
    font-size: calc(10rem /16);
    color: var(--color-text-grey);
}

.ejb-list__head {
    color: #000;
    text-transform: uppercase;
}

.ejb-list__cell {
    @media screen and (min-width: 768px) {
        padding: calc(.5vw + .5rem) calc(1.5vw + .5rem) calc(.5vw + .5rem) 0;
    }
}

tr td.ejb-list__cell--main {
    @media screen and (min-width: 768px) {
        width: 50%;
    }
}

td.ejb-list__cell--detail {
    padding-right: 0;
    text-align: right;
}

td.ejb-list__division {
    @media screen and (min-width: 768px) {
        width: 25%;
    }
}

td.ejb-list__cell--location {
    @media screen and (min-width: 768px) {
        width: 25%;
    }
}

.ejb-list__item-title {
    font-size: calc(16rem / 16);
    padding: 0;
    margin: 0;
}

.ejb-list__item-title-link {
    vertical-align: sub;
}

.ejb-list__item-detail-link {
    font-size: calc(10rem / 16);
}

.li-has-arrow {
    font-size: calc(10rem / 16);
}

.jobs__apply {
    background-color: var(--color-background-grey);
}

/* custom css before pimcore x */
@media screen and (max-width: 768px) {
    .jobs-container h3.underline {
        font-size: 1.375rem;
    }

    .jobs-container .vertical-gutter--4 > .vertical-gutter__item {
        margin-top: 1.75rem;
    }

    .jobs-container .vertical-gutter--4 > .vertical-gutter__item > h2 {
        font-size: 1.1875rem;
    }

    .jobs-container .vertical-gutter--4 > .vertical-gutter__item > h2 ~ div.wysiwyg {
        margin-top: 15px;
    }

    .jobs-container .wysiwyg > p ~ ul {
        margin-top: -15px;
    }
}