.newsletter-popup__bg,
.shub-popup__bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 767px) {
        height: 0;
        padding-top: 50%;
    }
}

.newsletter-popup__close,
.shub-popup__close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-grey);
    cursor: pointer;
    z-index: 1;
    box-shadow: none !important;
    @media screen and (max-width: 767px) {
        color: #fff;
        text-shadow: 0px 0px 4px #000000;
    }

    @media screen and (min-width: 768px) {
        background-color: #fff;
    }
}
.newsletter-popup__close:hover,
.shub-popup__close:hover {
    box-shadow: none !important;
}