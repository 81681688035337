.in-page-menu{
    @media screen and (max-width: 767px){
        top: 0;
    }
    z-index: 100;
}
.in-page-menu__wrapper {
    @media screen and (min-width: 768px) {
        display: inline-block;
        position: relative;
    }
}

.in-page-menu__dropdown {
    position: absolute;
    z-index: 1;
    text-align: left;
    top: 100%;
    margin-top: 3px;
    left: 0;
    right: 0;
    background: var(--color-background-grey);
    line-height: 2;

    @media screen and (min-width: 768px) {
        right: calc(-40rem / 16);
    }
}


.in-page-menu__btn {
    text-align: left;
    text-transform: uppercase;
    padding: calc(17rem / 16) calc(20rem / 16) calc(15rem / 16);
    background: var(--color-background-grey);
    border: none;
    display: inline-block;
    @media screen and (max-width: 767px){
        width: 100%;
    }
}
.in-page-menu__btn-label {
    font-family: var(--font-default);
    font-size: calc(13rem / 16);
    color: var(--color-text-grey-on-grey);
    line-height: 1.4;
}
.in-page-menu__btn-title {
    font-size: 1rem;
    font-family: var(--font-default-bold);
    line-height: 1.4;
    margin-top: -.25rem; /* pull to top to center correctly if the label is not shown */
}
.in-page-menu__btn-label + .in-page-menu__btn-title {
    margin-top: 0;
}



.in-page-menu__btn.collapsed .in-page-menu__icon{
    transform: rotate(180deg);
}

.in-page-menu__btn .in-page-menu__icon{
    transform: rotate(0);
}

.in-page-menu__icon{
    font-size: 7px;
}

.in-page-menu__first-level{
    padding: 20px;
    padding-top: 12px;
    padding-bottom: 9px;

    @media screen and (min-width: 768px) {
        padding-top: 11px;
        padding-right: 40px;
        padding-left: 20px;
        padding-bottom: 8px;
    }

}
.in-page-menu__first-level-link,
.in-page-menu__second-level-link {
    display: block;
    flex: auto;
    color: var(--color-text-grey-on-grey);
}
.in-page-menu__first-level-item:hover .in-page-menu__first-level-link,
.in-page-menu__first-level-link:focus,
.in-page-menu__first-level-link:active,
.in-page-menu__first-level-link.is-active,
.in-page-menu__second-level-item:hover .in-page-menu__second-level-link,
.in-page-menu__second-level-link:focus,
.in-page-menu__second-level-link:active,
.in-page-menu__second-level-link.is-active {
    color: var(--color-text-default);
}


/* Second level*/
.in-page-menu__second-level {
    position: absolute;
    top: 0;
    padding-top: 11px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
    background-color: var(--color-background-grey);

    @media screen and (max-width: 767px){
        left: 0;
        right: 0;
        z-index: 1;
        min-height: 100%;
        padding-top: 12px;
    }

    @media screen and (min-width: 768px){
        padding-left: 0;
        padding-right: 0;
        left: calc(100% - 20px);
        min-width: 100%;
        right: auto;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        z-index: -1;
    }
}
@media screen and (min-width: 768px) {
    .in-page-menu__second-level:before {
        content: '';
        position: absolute;
        z-index: -1;
        left: calc(-100% + 20px);
        top: 0;
        bottom: 0;
        right: 0;
        background-color: var(--color-background-grey);
    }
}
.in-page-menu__second-level-list {
    position: relative;

    @media screen and (min-width: 768px) {
        flex: auto;
        padding-left: 20px;
        padding-right: 20px;
        max-width: calc(280rem / 16);

        &:after {
            content: '';
            position: absolute;
            border-right: 1px solid var(--color-border-grey-on-grey);
            top: 9px;
            left: 0;
            bottom: 12px;

        }
    }
}
.in-page-menu__second-level-link {
    @media screen and (min-width: 768px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


.in-page-menu__touch-only{
    @media screen and (pointer: coarse) {
        display:block;
    }
    display:none;
}
.in-page-menu__close-second-level{
    white-space: nowrap;

    @media screen and (max-width: 767px){
        font-size: calc(13rem / 16);
    }
}

.in-page-menu__close-second-level .icon{
    font-size: calc(8rem / 16);
    margin-right: calc(7rem / 16);
    color: var(--color-text-grey-on-grey);
}
.in-page-menu__dropdown-detail-icon {
    @media screen and (max-width: 767px) {
        font-size: 7px;
        line-height: 1;
        height: auto;
        align-self: center;
        transform-origin: center center;
        transform: rotate(90deg);
        color: var(--color-text-grey-on-grey);
    }

    @media screen and (min-width: 768px) {
        display: none;
    }
}

