.fullpage-image-teaser__text-wrapper {
    position: static;
    width: 100%;
    text-align: center;
    @media screen and (min-width: 768px) {
        left: 0;
        bottom: 50%;
        height: 50%;
        display: flex;
        align-items: center;
        position: absolute;
    }
}

.fullpage-image-teaser__text-content {
    @media screen and (min-width: 768px) {
        width: 70%;
        margin: auto;
    }
}