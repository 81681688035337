.custom-collapse-additional-info__button:hover, .custom-collapse-additional-info__button:focus {
    text-decoration: none;
}
.custom-collapse-additional-info__button:focus,
.custom-collapse-additional-info__button:focus:active,
.custom-collapse-additional-info__button:not(:disabled):not(.disabled).active:focus,
.custom-collapse-additional-info__button:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    outline: 0;
}

.custom-collapse-additional-info__button:not(.collapsed) .custom-collapse-additional-info__hide-on-collapse,
.custom-collapse-additional-info__button.collapsed .custom-collapse-additional-info__show-on-collapse {
    display: block;
}

.custom-collapse-additional-info__button.collapsed .custom-collapse-additional-info__hide-on-collapse,
.custom-collapse-additional-info__button:not(.collapsed) .custom-collapse-additional-info__show-on-collapse {
    display: none;
}

.custom-collapse-additional-info__button--remove-bottom-padding {
    margin-bottom: -1.5rem;
}

.js-accordion-custom-toggle:not(.collapsed).js-accordion-custom-toggle--hide-on-collapse,
.js-accordion-custom-toggle.collapsed.js-accordion-custom-toggle--show-on-collapse {
    opacity: 1;
    visibility: visible;
}

.js-accordion-custom-toggle.collapsed.js-accordion-custom-toggle--hide-on-collapse,
.js-accordion-custom-toggle:not(.collapsed).js-accordion-custom-toggle--show-on-collapse {
    opacity: 0;
    visibility: hidden;
}

.js-accordion-custom-toggle:last-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.js-accordion-custom-toggle.custom-collapse-additional-info__button:last-child {
    width: auto;
    left: 50%;
    transform: translateX(-50%);
}
