.popover {
    padding: 24px;
    @media screen and (min-width: 768px) {
        padding: 30px;
    }
}
.popover-header {
    font-family: var(--font-default-heading);
    padding: 0;
}
.popover-body {
    padding: 0;
}