.teaser-card-slider .product-rating{
    margin-bottom: 0;
}

.teaser-card-slider .slick-list {
    margin-right: -15px;
}

.teaser-card-slider .slick-track{
    display: flex !important;
    margin-left: 0 !important;
}

.teaser-card-slider .slick-slide{
    height: inherit !important;
    display: flex;
    flex-direction: column;

    margin-right: 15px;

    @media screen and (max-width: 767px){
        width: 73.6vw;
    }
}

/*make sure all slides are the same height*/
.teaser-card-slider .slick-slide > div,
.teaser-card-slider .slick-slide > div > div,
.teaser-card-slider .slick-slide > div > div > div {
    display: flex !important; /* override inline style */
    flex-direction: column;
    flex: auto;
}
.teaser-card-slider .slick-arrow {
    top: 24%;
    transition: opacity 300ms ease-in;

    /* important to fix order issue */
    @media (min-width: 1200px){
        top: 28%;
    }

    @media (min-width: 1400px){
        top: 29%;
    }

    @media (min-width: 1451px){
        top: 26%;
    }

}
.teaser-card-slider .slick-prev {
    left: calc(-40rem/16);
}
.teaser-card-slider .slick-next {
    right: calc(-40rem/16);
}

.teaser-card-slider .slick-arrow.slick-disabled {
    opacity: 0;
}

.teaser-card-slider .slick-dots {
    margin-top: 46px;
}

.teaser-card-slider .slick-dots li {
    display: block;
    line-height: 0;
}

.teaser-card-slider .slick-dots button {
    position: relative;
    width: 16px;
    height: 16px;

    padding: 4px;
    margin: 0;
    border-radius: 9999px;

    color: transparent;
    font-size: 0;

    background: transparent;
    visibility: visible;
}

.teaser-card-slider .slick-dots button::before {
    content: '';

    display: block;
    width: 50%;
    height: 50%;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: var(--color-text-grey);
    border-radius: inherit;

    margin: auto;

    z-index: 1;

    transition: background-color 300ms ease-in;
}

.teaser-card-slider .slick-dots .slick-active button {
    border: 0;
}

.teaser-card-slider .slick-dots .slick-active button::before,
.teaser-card-slider .slick-dots button:focus::before,
.teaser-card-slider .slick-dots button:hover::before {
    background: var(--color-primary);
}
