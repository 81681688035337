.newsletter-footer__title {
    font-size: 1.4375rem;

    @media screen and (max-width: 767px) {
        font-size: 1.125rem;
    }
}

.newsletter-footer__title p {
    margin-bottom: 0;
}