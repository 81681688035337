.wysiwyg {
    line-height: var(--line-height-body-text);
    position: relative;
}
.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 1.5rem;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg ul,
.wysiwyg ol  {
    line-height: var(--line-height-bullet-points);
}
.wysiwyg ul > li,
.wysiwyg ol > li {
    margin-bottom: 0;
    padding-left: calc(14rem / 16);
}
.wysiwyg ul > li:before {
    top: .45rem;
}

.wysiwyg iframe {
    display: block;
}

.wysiwyg--multiCol p{
    @media screen and (min-width: 768px) {
        column-count: 2;
        column-gap: calc(32px + calc(8.3334%));
    }
}
.wysiwyg.wysiwyg ul.list-with-checkmarks>li:before {
    color: var(--color-primary);
    font-weight: bold;
    top: calc(2rem / 16);
    font-size: calc(12rem / 16);
}
.wysiwyg.wysiwyg ul.list-with-checkmarks>li {
    margin-bottom: 0;
    padding-left: 1.8rem;
}


.wysiwyg a.btn.btn-primary{
    color: white;
}

.wysiwyg a,
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-text-grey);
}
.wysiwyg--on-grey-bg a,
.wysiwyg--on-grey-bg a:hover,
.wysiwyg--on-grey-bg a:focus,
.wysiwyg--on-grey-bg a:active {
    color: var(--color-text-grey-on-grey);
}
.wysiwyg a.text-secondary:hover,
.wysiwyg a.text-secondary:focus,
.wysiwyg a.text-secondary:active {
    /* override bootstrap */
    color: var(--color-white) !important;
}

.wysiwyg a.btn.btn-primary:hover,
.wysiwyg a.btn.btn-primary:focus,
.wysiwyg a.btn.btn-primary:active{
    color: white;
    text-decoration: none;
}

.wysiwyg a.btn.btn-secondary:hover,
.wysiwyg a.btn.btn-secondary:focus{
    text-decoration: none;
}

/* First child corrections*/
.wysiwyg h1:first-child,
.wysiwyg .h1:first-child {
    margin-top: calc(-3rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(-5rem / 16);
    }
}
.wysiwyg h2:first-child,
.wysiwyg .h2:first-child {
    margin-top: calc(-3rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(-4rem / 16);
    }
}
.wysiwyg h3:first-child,
.wysiwyg .h3:first-child {
    margin-top: calc(-2rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(-2rem / 16);
    }
}
.seo-collapse .wysiwyg h1:first-child,
.seo-collapse .wysiwyg .h1:first-child,
.seo-collapse .wysiwyg h2:first-child,
.seo-collapse .wysiwyg .h2:first-child,
.seo-collapse .wysiwyg h3:first-child,
.seo-collapse .wysiwyg .h3:first-child,
.seo-collapse .wysiwyg p:first-child,
.wysiwyg.wysiwyg--no-first-child-correction h1:first-child,
.wysiwyg.wysiwyg--no-first-child-correction .h1:first-child,
.wysiwyg.wysiwyg--no-first-child-correction h2:first-child,
.wysiwyg.wysiwyg--no-first-child-correction .h2:first-child,
.wysiwyg.wysiwyg--no-first-child-correction h3:first-child,
.wysiwyg.wysiwyg--no-first-child-correction .h3:first-child,
.wysiwyg.wysiwyg--no-first-child-correction p:first-child {
    margin-top: .1em; /* for umlaute */
}


/*Last child corrections*/
.wysiwyg h1:last-child,
.wysiwyg .h1:last-child {
    margin-bottom: calc(-8rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-14rem / 16);
    }
}
.wysiwyg h2:last-child,
.wysiwyg .h2:last-child {
    margin-bottom: calc(-8rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-12rem / 16);
    }
}
.wysiwyg h3:last-child,
.wysiwyg .h3:last-child {
    margin-bottom: calc(-6rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-7rem / 16);
    }
}

.wysiwyg p:last-child {
    margin-bottom: calc(-5rem / 16);
}
.wysiwyg--no-last-child-correction h1:last-child,
.wysiwyg--no-last-child-correction .h1:last-child,
.wysiwyg--no-last-child-correction h2:last-child,
.wysiwyg--no-last-child-correction .h2:last-child,
.wysiwyg--no-last-child-correction h3:last-child,
.wysiwyg--no-last-child-correction .h3:last-child,
.wysiwyg--no-last-child-correction p:last-child {
    margin-bottom: 0;
}



.wysiwyg h1,
.wysiwyg .h1,
.wysiwyg h2,
.wysiwyg .h2,
.wysiwyg h3,
.wysiwyg .h3 {
    margin-bottom: 0;
}
.wysiwyg h1 + *:before,
.wysiwyg .h1 + *:before,
.wysiwyg h2 + *:before,
.wysiwyg .h2 + *:before,
.wysiwyg h3 + *:before,
.wysiwyg .h3 + *:before {
    background: var(--color-border-grey);
    display: block;
    height: 1px;
    width: 70px;
    content: '';
    max-width: 80%;
    margin-top: 3px;
    margin-bottom: 12px;
}
.wysiwyg h2 + *:before,
.wysiwyg .h2 + *:before {
    margin-top: 3px;
}
.wysiwyg h3 + *:before,
.wysiwyg .h3 + *:before {
    margin-top: 8px;
}
.wysiwyg--white h1 + *:before,
.wysiwyg--white .h1 + *:before,
.wysiwyg--white h2 + *:before,
.wysiwyg--white .h2 + *:before,
.wysiwyg--white h3 + *:before,
.wysiwyg--white .h3 + *:before {
    background: #fff;
}
.wysiwyg--center h1 + *:before,
.wysiwyg--center .h1 + *:before,
.wysiwyg--center h2 + *:before,
.wysiwyg--center .h2 + *:before,
.wysiwyg--center h3 + *:before,
.wysiwyg--center .h3 + *:before {
    margin-left: auto;
    margin-right: auto;
}
@media screen and (min-width: 768px) {
    .wysiwyg--center h1 + *:before,
    .wysiwyg--center .h1 + *:before,
    .wysiwyg--center h2 + *:before,
    .wysiwyg--center .h2 + *:before,
    .wysiwyg--center h3 + *:before,
    .wysiwyg--center .h3 + *:before {
        margin-left: auto;
        margin-right: auto;
    }
}

/* START:headline block starting with h1 */
.wysiwyg h1 + *:before,
.wysiwyg .h1 + *:before {
    margin-top: 1px;
    margin-bottom: 12px;

    @media screen and (max-width: 767px) {
        margin-top: 6px;
    }
}
.wysiwyg h1 + h2:before,
.wysiwyg .h1 + h2:before,
.wysiwyg h1 + .h2:before,
.wysiwyg .h1 + .h2:before {
    margin-bottom: 12px;

    @media screen and (min-width: 768px) {
        margin-bottom: 10px;
    }
}
.wysiwyg h1 + h2:not(:last-child),
.wysiwyg .h1 + h2:not(:last-child),
.wysiwyg h1 + .h2:not(:last-child),
.wysiwyg .h1 + .h2:not(:last-child) {
    margin-bottom: 15px;

    @media screen and (min-width: 768px) {
        margin-bottom: 11px;
    }
}
.wysiwyg h1 + h3:before,
.wysiwyg .h1 + h3:before,
.wysiwyg h1 + .h3:before,
.wysiwyg .h1 + .h3:before {
    margin-bottom: 13px;
}
.wysiwyg h1 + h3:not(:last-child),
.wysiwyg .h1 + h3:not(:last-child) {
    margin-bottom: 16px;
}
.wysiwyg h1 + h2 + *:before,
.wysiwyg h1 + .h2 + *:before,
.wysiwyg h1 + h3 + *:before,
.wysiwyg h1 + h4 + *:before,
.wysiwyg h1 + h5 + *:before,
.wysiwyg h1 + h6 + *:before,
.wysiwyg .h1 + h2 + *:before,
.wysiwyg .h1 + .h2 + *:before,
.wysiwyg .h1 + h3 + *:before,
.wysiwyg .h1 + h4 + *:before,
.wysiwyg .h1 + h5 + *:before,
.wysiwyg .h1 + h6 + *:before,
.wysiwyg h1 + h2 + h3 + *:before,
.wysiwyg h1 + h2 + h4 + *:before,
.wysiwyg h1 + h2 + h5 + *:before,
.wysiwyg h1 + h2 + h6 + *:before ,
.wysiwyg h1 + h3 + h4 + *:before,
.wysiwyg h1 + h3 + h5 + *:before,
.wysiwyg h1 + h3 + h6 + *:before,
.wysiwyg h1 + h4 + h5 + *:before,
.wysiwyg h1 + h4 + h6 + *:before,
.wysiwyg h1 + h5 + h6 + *:before,
.wysiwyg h1 + table:before,
.wysiwyg .h1 + table:before,
.wysiwyg h2 + table:before,
.wysiwyg .h2 + table:before,
.wysiwyg h3 + table:before,
.wysiwyg .h3 + table:before,
.wysiwyg h1 + .table-responsive:before,
.wysiwyg .h1 + .table-responsive:before,
.wysiwyg h2 + .table-responsive:before,
.wysiwyg .h2 + .table-responsive:before,
.wysiwyg h3 + .table-responsive:before,
.wysiwyg .h3 + .table-responsive:before{
    display: none;
}

.wysiwyg h1 + h3:not(:last-child),
.wysiwyg h1 + h4:not(:last-child),
.wysiwyg h1 + h5:not(:last-child),
.wysiwyg h1 + h6:not(:last-child),
.wysiwyg .h1 + h3:not(:last-child),
.wysiwyg .h1 + h4:not(:last-child),
.wysiwyg .h1 + h5:not(:last-child),
.wysiwyg .h1 + h6:not(:last-child),
.wysiwyg h1 + h2 + h3:not(:last-child),
.wysiwyg h1 + h2 + h4:not(:last-child),
.wysiwyg h1 + h2 + h5:not(:last-child),
.wysiwyg h1 + h2 + h6:not(:last-child),
.wysiwyg h1 + h3 + h4:not(:last-child),
.wysiwyg h1 + h3 + h5:not(:last-child),
.wysiwyg h1 + h3 + h6:not(:last-child),
.wysiwyg h1 + h4 + h5:not(:last-child),
.wysiwyg h1 + h4 + h6:not(:last-child),
.wysiwyg h1 + h5 + h6:not(:last-child){
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
        margin-bottom: 15px;
    }
}
/* END: headline block starting with h1 */

/* START:headline block starting with h2 */
.wysiwyg h2 + *:before,
.wysiwyg .h2 + *:before {
    margin-top: 3px;
    margin-bottom: 12px;

    @media screen and (max-width: 767px) {
        margin-top: 7px;
    }
}
.wysiwyg h2 + h3:before,
.wysiwyg .h2 + h3:before,
.wysiwyg h2 + .h3:before,
.wysiwyg .h2 + .h3:before {
    margin-bottom: 12px;
}
.wysiwyg h2 + h3:not(:last-child),
.wysiwyg .h2 + h3:not(:last-child) {
    margin-bottom: 15px;

    @media screen and (max-width: 767px) {
        margin-bottom: 17px;
    }
}
.wysiwyg h2 + h3 + *:before,
.wysiwyg h2 + h4 + *:before,
.wysiwyg h2 + h5 + *:before,
.wysiwyg h2 + h6 + *:before,
.wysiwyg .h2 + h3 + *:before,
.wysiwyg .h2 + h4 + *:before,
.wysiwyg .h2 + h5 + *:before,
.wysiwyg .h2 + h6 + *:before,
.wysiwyg h2 + h3 + h4 + *:before,
.wysiwyg h2 + h3 + h5 + *:before,
.wysiwyg h2 + h3 + h6 + *:before,
.wysiwyg h2 + h4 + h5 + *:before,
.wysiwyg h2 + h4 + h6 + *:before,
.wysiwyg h2 + h5 + h6 + *:before {
    display: none;
}

.wysiwyg h2 + h4:not(:last-child),
.wysiwyg h2 + h5:not(:last-child),
.wysiwyg h2 + h6:not(:last-child),
.wysiwyg .h2 + h3:not(:last-child),
.wysiwyg .h2 + h4:not(:last-child),
.wysiwyg .h2 + h5:not(:last-child),
.wysiwyg .h2 + h6:not(:last-child),
.wysiwyg h2 + h3 + h4:not(:last-child),
.wysiwyg h2 + h3 + h5:not(:last-child),
.wysiwyg h2 + h3 + h6:not(:last-child),
.wysiwyg h2 + h4 + h5:not(:last-child),
.wysiwyg h2 + h4 + h6:not(:last-child),
.wysiwyg h2 + h5 + h6:not(:last-child){
    margin-bottom: 15px;

    @media screen and (min-width: 768px) {
        margin-bottom: 1rem;
    }
}
/* END: headline block starting with h2 */

.wysiwyg.text-center h1:after, .wysiwyg.text-center h2:after, .wysiwyg.text-center h3:after, .wysiwyg.text-center h4:after, .wysiwyg.text-center h5:after, .wysiwyg.text-center h6:after {/*style copied from base-typo.css "underline" class*/
    margin-left:auto;
    margin-right:auto;
}

.wysiwyg.text-right h1:after, .wysiwyg.text-right h2:after, .wysiwyg.text-right h3:after, .wysiwyg.text-right h4:after, .wysiwyg.text-right h5:after, .wysiwyg.text-right h6:after {/*style copied from base-typo.css "underline" class*/
    margin-left:auto;
}

.wysiwyg strong, .wysiwyg b{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}

.wysiwyg .btn-primary{
    color: #fff !important;
}
.wysiwyg .btn-outline-primary {
    color: var(--color-text-default);
}

.wysiwyg .btn:hover,
.wysiwyg .btn:active,
.wysiwyg .btn:focus {
    text-decoration: none;
}

.wysiwyg .btn-secondary,
.wysiwyg .btn-secondary:hover,
.wysiwyg .btn-secondary:active,
.wysiwyg .btn-secondary:focus {
    color: var(--color-text-default);
    border-color: var(--color-text-default);
}

.wysiwyg .table-responsive{
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}
.wysiwyg .table-responsive table {
    word-break: normal;
}

.wysiwyg table {
    margin-bottom: 1rem;
    color: #212529;
    border: none;
}
.wysiwyg table td,
.wysiwyg table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}
.wysiwyg table tbody tr:last-child th,
.wysiwyg table tbody tr:last-child td {
    border-bottom: 1px solid #000;
}
.wysiwyg table thead th {
    vertical-align: bottom;
}
.wysiwyg table tbody + tbody {
    border-top: 2px solid #f3f3f3
}
.wysiwyg table tbody tr,
.wysiwyg table thead tr {
    background-color: #fff;
}
.wysiwyg table tbody tr:nth-of-type(odd) {
    background-color: #f3f3f3;
}

.wysiwyg--center {
    text-align: center;
}
.wysiwyg--md-center {
    @media screen and (min-width: 768px) {
        text-align: center;
    }
}
.wysiwyg--white {
    color: #ffffff;
}
.wysiwyg--white h1,
.wysiwyg--white h2,
.wysiwyg--white h3,
.wysiwyg--white h4,
.wysiwyg--white h5,
.wysiwyg--white h6,
.wysiwyg--white .h1,
.wysiwyg--white .h2,
.wysiwyg--white .h3,
.wysiwyg--white .h4,
.wysiwyg--white .h5,
.wysiwyg--white .h6 {
    color: #ffffff;
}

/* Special wysiwyg class for editors */
.wysiwyg .text-blue,
.wysiwyg .text-blue:hover,
.wysiwyg .text-blue:focus,
.wysiwyg .text-blue:active {
    color: var(--color-blue);
}

/*
*  Usually the last WYSIWYG element does not have a margin bottom.
*  This class allows headlines to add their default margin if they are the last element in the wysiwyg
*  This behaviour is needed for some special cases e.g. headline area
*/
.wysiwyg.wysiwyg--allow-default-headline-margin h1:last-child,
.wysiwyg.wysiwyg--allow-default-headline-margin .h1:last-child {
    margin-bottom: var(--default-h1-margin);
}
.wysiwyg.wysiwyg--allow-default-headline-margin h2:last-child,
.wysiwyg.wysiwyg--allow-default-headline-margin .h2:last-child {
    margin-bottom: var(--default-h2-margin);
}
.wysiwyg.wysiwyg--allow-default-headline-margin h3:last-child,
.wysiwyg.wysiwyg--allow-default-headline-margin .h3:last-child {
    margin-bottom: var(--default-h3-margin);
}

.wysiwyg__btn-wrapper {
    margin-top: calc(19rem / 16);
}
.wysiwyg__btn-wrapper:first-child {
    margin-top: 0;
}
h1 + .wysiwyg__btn-wrapper,
.h1 + .wysiwyg__btn-wrapper {
    margin-top: 1px;
}
h1 + .wysiwyg__btn-wrapper:before,
.h1 + .wysiwyg__btn-wrapper:before {
    margin-top: 0;
    margin-bottom: 15px;
}
h2 + .wysiwyg__btn-wrapper,
.h2 + .wysiwyg__btn-wrapper,
h3 + .wysiwyg__btn-wrapper,
.h3 + .wysiwyg__btn-wrapper {
    margin-top: 1px;
}
h2 + .wysiwyg__btn-wrapper:before,
.h2 + .wysiwyg__btn-wrapper:before,
h3 + .wysiwyg__btn-wrapper:before,
.h3 + .wysiwyg__btn-wrapper:before {
    margin-bottom: 14px;
}
h1 + h2 + .wysiwyg__btn-wrapper,
.h1 + h2 + .wysiwyg__btn-wrapper,
h1 + .h2 + .wysiwyg__btn-wrapper,
.h1 + .h2 + .wysiwyg__btn-wrapper {
    margin-top: .75rem;

    @media screen and (min-width: 768px) {
        margin-top: calc(13rem / 16);
    }
}
h1 + h3 + .wysiwyg__btn-wrapper,
.h1 + h3 + .wysiwyg__btn-wrapper,
h1 + .h3 + .wysiwyg__btn-wrapper,
.h1 + .h3 + .wysiwyg__btn-wrapper,
h2 + h3 + .wysiwyg__btn-wrapper,
.h2 + h3 + .wysiwyg__btn-wrapper,
h2 + .h3 + .wysiwyg__btn-wrapper,
.h2 + .h3 + .wysiwyg__btn-wrapper {
    margin-top: .75rem;

    @media screen and (min-width: 768px) {
        margin-top: calc(18rem / 16);
    }
}
.wysiwyg > p + .wysiwyg__btn-wrapper,
.wysiwyg > ol + .wysiwyg__btn-wrapper,
.wysiwyg > ul + .wysiwyg__btn-wrapper,
.wysiwyg > table + .wysiwyg__btn-wrapper {
    margin-top: calc(-4rem / 16);
}

/* use the nav style for links in this area. This is used for the teaser-cards component*/
.wysiwyg--nav-link-style a {
    color: var(--color-text-default);
}

.wysiwyg--nav-link-style a:hover,
.wysiwyg--nav-link-style a:focus,
.wysiwyg--nav-link-style a:active {
    color: #000000;
    text-decoration: none;
}

/* fixed column elements */
.wysiwyg .table-responsive .fixed-column {
    position: absolute;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-top: 1px solid #f3f3f3;
}

.wysiwyg .table-responsive .fixed-column .fixed-cell {
    padding: 0.75rem;
    vertical-align: top;
    background-color: #fff;
    box-sizing: content-box;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #f3f3f3;
}

.wysiwyg .table-fixed thead th {
    vertical-align: top;
}

.wysiwyg .table-responsive .fixed-column .fixed-cell:nth-of-type(even) {
    background-color: #f3f3f3;
    border-top: 1px solid #f3f3f3;
}

.wysiwyg .table-responsive .fixed-column .fixed-cell:first-child {
    vertical-align: bottom;
}

.wysiwyg .table-responsive .fixed-column .fixed-cell:last-child {
    border-bottom: 1px solid #000;
}

.wysiwyg .table-responsive table .fixed-cell {
    position: absolute;
    border-top: none;
}

.wysiwyg .table-responsive table thead th.fixed-cell {
    border-inline-start: 2px solid #f3f3f3;
    writing-mode: vertical-lr;
}

.wysiwyg .no-divider + *:before {
    display: none;
}
