.wishlist__btn.is-active .icon:after {
    position: absolute;
    top: 0;
    left: 0;
    content: url('/static/img/icons/heart-filled.svg');
    display: flex;
    justify-content: center;
    align-items: center;
}
.wishlist__btn.is-active .icon:before {
    opacity: 0;
}
.wishlist__remove{
    position: absolute;
    z-index: 100;
    top: 10px;
    right: 25px;
}
.wishlist__remove:hover {
    color: var(--color-primary)
}

@keyframes wiggle{
    20%  { transform: translateX(4px);  }
    40%  { transform: translateX(-4px); }
    60%  { transform: translateX(2px);  }
    80%  { transform: translateX(-1px); }
    100% { transform: translateX(0);    }
}