.lang-modal__btn, .modal--lang .btn-icon {
    display:inline-block;
    color: var(--color-primary);
}

.lang-modal__languages a.is-active {
    font-family: var(--font-default);
    color: var(--color-almost-black);
    font-weight: bold;
}

.lang-modal__btn.highlight {
    color: var(--color-almost-black);
    font-weight: bold;
}

.btn-icon img, .btn-icon svg {
    border: 1px solid #eeeeee;
    border-radius: 50%
}

.modal-backdrop {
    z-index: 5001;
}

.modal {
    z-index: 5002;
}
