.headline-background-highlight__headline {
    @media screen and (max-width: 767px){
        font-size: calc(20rem /16);
    }
}

.headline-background-highlight__subheadline {
    @media screen and (max-width: 767px){
        font-size: calc(16rem /16);
    }
}