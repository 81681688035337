.ovx__diract-voice .card-headline {
    font-size: 24px;

    @media (min-width: 768px) {
        font-size: 30px;
        line-height: 40px;
    }
}

.ovx__diract-voice__modal-dialog {
    @media (min-width: 576px) {
        max-width: 574px;
    }
}

.ovx__diract-voice__modal-body {
    padding-bottom: calc(40rem / 16);

    @media (min-width: 768px) {
        padding-top: calc(32rem / 16);
        padding-bottom: calc(70rem / 16);
    }
}

.ovx__diract-voice__modal-img {
    width: calc(150rem/16);

    @media (min-width: 768px) {
        width: calc(140rem/16);
    }
}

.ovx__diract-voice__speechbubble {
    position: relative;
    min-height: calc(90rem / 16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    background: #eee;
}

.ovx__diract-voice__speechbubble--active {
    opacity: 1;
}

.ovx__diract-voice__speechbubble::before {
    content: '';

    position: absolute;
    top: 100%;
    left: calc(32rem / 16);

    display: block;
    width: calc(28rem / 16);
    height: calc(50rem / 16);

    margin-top: calc(-42.5rem / 16);
    padding: calc(10rem / 16);

    pointer-events: none;
    transform: rotate(45deg) skew(345deg, 65deg) scale(-1);
    background: #eee;
    z-index: 1;
}

.ovx__diract-voice__speechbubble-inner {
    position: relative;
    z-index: 2;
}

.ovx__diract-voice__modal-toggle .icon {
    position: relative;
    top: -2px;
    font-size: calc(34rem / 16);
}

.ovx__diract-voice__track-toggle {
    font-size: calc(18rem / 16);
}

.ovx__diract-voice__track-toggle--play,
.ovx__diract-voice__track-wrapper--is-playing .ovx__diract-voice__track-toggle--pause {
    display: block;
}

.ovx__diract-voice__track-toggle--pause,
.ovx__diract-voice__track-wrapper--is-playing .ovx__diract-voice__track-toggle--play {
    display: none;
}

.ovx__diract-voice__modal--editmode {
    pointer-events: none;
    visibility: hidden;
}

.ovx__diract-voice__modal--editmode.show {
    pointer-events: auto;
    visibility: visible;
}

.ovx__diract-voice--in-viewport .ovx__diract-voice__modal-toggle .icon {
    animation: diractVoicePulse 2.5s infinite;
}

.ovx__diract-voice--in-viewport .ovx__diract-voice__modal.show + .ovx__diract-voice__modal-toggle .icon {
    animation: none;
}

.ovx__diract-voice__track-duration {
    min-width: 60px;
    text-align: right;
}

@keyframes diractVoicePulse {
  0% {
    transform: scale(.8);
  }

  30% {
    transform: scale(.8);
  }

  50% {
    transform: scale(1);
  }

  70% {
    transform: scale(.8);
  }

  100% {
    transform: scale(.8);
  }
}
