.accordion .accordion__icon{
    transform: rotate(0deg);
    transition-duration: .25s;
    font-size: calc(8rem / 16);
}

.accordion.collapsed .accordion__icon{
    transform: rotate(180deg);
    transform-origin: center center;
}

.accordion .accordion__header {
    padding: calc(16rem  /16) calc(24rem /16);
}

.accordion .accordion__headline {
    font-size: calc(16rem /16);
    text-align: left;
    line-height: 1;
}

.accordion .accordion__body {
    padding: 0rem calc(24rem/16) calc(16rem /16) calc(24rem /16);
}
