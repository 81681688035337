.content-block--force,
.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(100rem / 16);
    }
}


.pimcore_area_hero-slider + .breadcrumb-container + .content-block,
.pimcore_area_hero-slider + .breadcrumb-container + .pimcore_area_content > .content-block,
.pimcore_area_headline-background-highlight + .breadcrumb-container + .content-block,
.pimcore_area_headline-background-highlight + .breadcrumb-container + .pimcore_area_content > .content-block {
    /* add a margin-top to the content block after a pimcore_area_headline-background-highlight or pimcore_area_hero-slider
     because the area does not have a margin & the breadcrumb is not shown on mobile */
    @media screen and (max-width: 767px) {
        margin-top: calc(40rem / 16);
    }
}

.pimcore_area_content + .pimcore_area_content.pimcore_area_button .content-block,
.content-block + .pimcore_area_content.pimcore_area_button .content-block {
    margin-top: calc(25rem / 16);
}

.content-block.content-block--small + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block.content-block--small {
    margin-top: 1rem;

    @media screen and (min-width: 768px) {
        margin-top: 1.25rem;
    }
}
.content-block--reduce-margin-bottom {
    margin-bottom: calc(-20rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-25rem / 16);
    }
}

.content-block--remove-next-margin {
    margin-bottom: calc(-40rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-100rem / 16);
    }
}