.number-spinner {
    height: calc(20rem / 16);
    justify-content: center;
}
.number-spinner__btn{
    padding: calc(2rem / 16);
    color: var(--color-secondary);
    height: calc(20rem / 16);
    width: calc(20rem / 16);
    background-color: var(--color-background-grey);
    font-size: calc(9rem / 16);
}
.number-spinner__input{
    font-size: calc(14rem / 16);
    height: calc(20rem / 16);
    max-width: calc(30rem / 16);
    background-color: var(--color-background-grey);
}