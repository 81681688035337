.dotmodule__btn-wrapper {
    position: absolute;
    width: calc(44rem/16);
    height: calc(44rem/16);

    transform: translate(-50%, -50%);
}

.dotmodule__btn {
    width: calc(24rem/16);
    height: calc(24rem/16);

    top: 50%;
    left: 50%;

    border-radius: 9999px;
    border: 1px solid var(--color-border-grey-on-grey);

    background-color: var(--color-white);

    transform: translate(-50%, -50%);
    transition-property: border-color;

    @media screen and (min-width: 768px) {
        width: calc(24rem/16);
        height: calc(24rem/16);
    }
}

.dotmodule__btn:active,
.dotmodule__btn:focus,
.dotmodule__btn:hover {
    background-color: var(--color-white);
}

.dotmodule__btn-wrapper:active .dotmodule__btn,
.dotmodule__btn-wrapper:focus .dotmodule__btn,
.dotmodule__btn-wrapper:hover .dotmodule__btn {
    background-color: rgba(255,255,255,0.65);
}

.dotmodule__btn::before {
    content: '';
    display: block;
    width: calc(34rem/16);
    height: calc(34rem/16);

    position: absolute;
    top: 50%;
    left: 50%;

    border-radius: 9999px;
    border: 2px solid var(--color-white);

    transform: translate(-50%, -50%);

    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    transition-property: opacity, visibility;
    transition-duration: .33s;

    @media screen and (min-width: 768px) {
        width: calc(34rem/16);
        height: calc(34rem/16);
    }
}

.dotmodule__btn-wrapper:focus .dotmodule__btn,
.dotmodule__btn-wrapper[aria-expanded="true"] .dotmodule__btn {
    border-color: var(--color-white);
    background-color: var(--color-white);
}

.dotmodule__btn-wrapper:focus .dotmodule__btn::before,
.dotmodule__btn-wrapper[aria-expanded="true"] .dotmodule__btn::before {
    opacity: 1;
    visibility: visible;
}

.dotmodule__btn.box-shadow {
    position: absolute;
}

.dotmodule__btn.box-shadow::after {
    border-radius: 9999px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
}

[data-tippy-root] {
    z-index: 999 !important;
}

.tippy-box[data-theme="ovx"] {
    border-radius: 0;
    background-color: transparent;
    width: 9999px;
}

.tippy-box[data-theme="ovx"]::after {
    content: '';

    display: block;
    width: calc(250rem/16);
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    margin: auto;

    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    pointer-events: none;
    z-index: 1;
}

.tippy-box[data-theme="ovx"] .tippy-content {
    width: calc(250rem/16);
    padding: calc(20rem/16);
    margin-left: auto;
    margin-right: auto;

    background-color: var(--color-white);
    color: var(--color-text-grey);

    z-index: 3;
}

.js-dotmodule__dot-close {
    position: absolute;
    top: calc(15rem/16);
    right: calc(10rem/16);

    font-size: calc(10rem/16);
    color: var(--color-text-grey);

    z-index: 1;

    @media screen and (min-width: 768px) {
        top: calc(10rem/16);
    }
}

.tippy-box[data-theme="ovx"] > .tippy-arrow {
    z-index: 2;
}

.tippy-box[data-theme="ovx"] > .tippy-arrow::before {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
}

.tippy-box[data-theme="ovx"][data-placement^=top] > .tippy-arrow::before,
.tippy-box[data-theme="ovx"][data-placement^=bottom] > .tippy-arrow::before {
    top: 100%;
    bottom: auto;
    left: -15px;

    width: 20px;
    height: 10px;

    border: 0;

    transform: rotate(45deg) skew(65deg,355deg) translateY(-70%);

    background-color: var(--color-white);
}

.tippy-box[data-theme="ovx"][data-placement^=bottom] > .tippy-arrow::before {
    top: auto;
    bottom: 100%;
    left: auto;
    right: -15px;

    transform: rotate(45deg) skew(65deg,355deg) translateY(-15%) scale(-1);
}

.tippy-box[data-theme="ovx"] .dotmodule__header {
    padding-right: calc(10rem/16 + 10px);

    @media screen and (min-width: 768px) {
        padding-right: calc(10rem/16);
    }
}

.tippy-box[data-theme="ovx"] .dotmodule__header,
.tippy-box[data-theme="ovx"] .dotmodule__text {
    font-size: calc(13rem/16) !important;

    @media screen and (min-width: 768px) {
        font-size: calc(13rem/16) !important;
    }
}
