.checkout-sidebar__hl {
    text-transform: uppercase;
    border-bottom: 0;
    font-size: calc(19rem/16);
    line-height: 1.2;
    margin-bottom: var(--default-h3-margin);
    padding-bottom: 0;

    @media screen and (max-width: 767px){
        font-size: calc(18rem/16);
    }
}
.checkout-sidebar__item {
    font-size: calc(16rem / 16);
    line-height: calc(19/16);
    border-bottom: calc(1rem / 16) solid var(--color-border-grey);
}
.checkout-sidebar__bottom__total {
    font-size: calc(20rem / 16);
}