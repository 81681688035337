.pswp {
    --pswp-bg: #f0f0f0;
}

.pswp__top-bar,
.pswp__button--arrow {
    @media (min-width: 992px) and (max-width: 1199px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) and (max-width: 1399px) {
        max-width: 1140px;
    }

    @media (min-width: 1400px) {
        max-width: 1400px;
    }
}

.pswp__top-bar {
    top: 15px;
    right: 0;

    padding-left: 15px;
    padding-right: 15px;

    margin-left: auto;
    margin-right: auto;

    @media (min-width: 768px) {
        top: 50px;
    }
}

.pswp .pswp__top-bar {
    opacity: 1;
}

.pswp__product-title {
    margin: 15px 0 0;

    @media (min-width: 768px) {
        margin: 0;
    }
}

.pswp__button--close {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #878787;
    opacity: 1;

    margin: 0;
    transform: translate(19px, -24px);

    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pswp__button--close::before {
    content: var(--icon-ortovox-close-flyout);

    display: block;
    width: 14px;
    height: 14px;
}

.pswp__button--close .pswp__icn {
    display: none;
}

.pswp__button--arrow {
    display: none;

    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    height: 1px;
    overflow: visible;
    align-items: center;
    justify-content: flex-start;
    pointer-events: none !important;

    @media (min-width: 768px) {
        display: flex;
    }
}

.pswp__button--arrow--next {
    justify-content: flex-end;
}

.pswp__button--arrow .pswp__icn {
    position: static;
    width: 64px;
    height: 64px;

    margin-top: -32px;
    margin-left: -16px;
    padding: 16px;

    fill: #878787;

    pointer-events: auto;
    cursor: pointer;
}

.pswp__img--placeholder {
    display: none;
}

.pswp__button--arrow--next .pswp__icn {
    margin-left: 0;
    margin-right: -16px;
}

.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
    @media (min-width: 768px) {
        cursor: zoom-in;
    }
}

.pswp--click-to-zoom.pswp--zoomed-in .pswp__img,
.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
    @media (min-width: 768px) {
        cursor: zoom-out;
    }
}

.pswp__loading-icon-wrapper {
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    background-color: rgba(255,255,255,.6);
}

.pswp__preloader--active .pswp__icn {
    opacity: 0;
}

.pswp__preloader--active ~ .pswp__loading-icon-wrapper {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.pswp__loading-icon {
    display: block;
    max-width: 100%;
    width: 200px;
    height: 200px;
}
