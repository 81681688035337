.table__price {
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}
.table__icon-link {
    color: var(--color-text-grey);
}
.table--checkout {
    margin-bottom: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(35rem / 16);
    }
}
.table--checkout thead th {
    border-bottom: calc(1rem / 16) solid var(--color-border-grey);
}
.table--checkout th {
    font-size: calc(14rem / 16);
    padding: calc(5rem / 16) calc(12rem / 16);
}
.table--checkout td {
    border-top: 0;
    padding: calc(20rem / 16) calc(12rem / 16);
}
.table--checkout tbody > tr + tr {
    border-top: calc(1rem / 16) solid var(--color-border-grey);
}
.table--checkout tbody > tr:last-child {
    border-bottom: calc(1rem / 16) solid var(--color-border-grey);
}
.table--checkout td.cart__number-spinner {
    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
}

.table--compare{
    table-layout: fixed;
    @media screen and (max-width: 767px) {
        white-space: nowrap;
    }
}

.table--compare tr:first-child td{
    border: none;
}

.table--compare th, .table--compare td{
    border: 1px solid var(--color-border-grey);
    border-right: none;
    border-left: none;
}

.table--compare tr:last-child th, .table--compare tr:last-child td{
    border: none;
}

.table--compare th{
    color: var(--color-primary);
    background-color: #fff;
    vertical-align: top;
    text-align: left;
    padding: .75em;
    width: 20%;
}

.table--compare__disabled{
    vertical-align: center;
}

.table--compare td{
    text-align: center;
    width: 27%;
    min-width: 27%;
    max-width: 27%;
}

.table--compare-awards{
    padding-top: calc(12rem / 16);
    padding-bottom: calc(12rem / 16);
}


.table-event-module thead{
     @media screen and (max-width: 767px) {
         display: none
     }
}
.table-event-module tr{
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
}
.table-event-module tr:nth-child(2n+1){
      @media screen and (max-width: 767px) {
          background-color: var(--color-black-09);
      }
}
.table-event-module td{
    @media screen and (max-width: 767px) {
        display: flex;
        border: 0;
        padding: 0 .75rem;
    }
}
.table-event-module td:first-of-type{
    @media screen and (max-width: 767px) {
        padding-top: .75rem;
    }
}

.table-event-module td .table-event-module__title{
    font-family: var(--font-default-bold);
    flex-basis: 40%;
    flex-shrink: 0;
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.table-event-module .btn{
    @media screen and (max-width: 767px) {
        width: 100%;
        margin: .75rem 0;
    }
}

.table-event-module .text-nowrap{
    @media screen and (max-width: 767px) {
        white-space: normal !important;
    }
}