.manual{
    @media screen and (min-width: 1200px){
        max-width: 86%;
    }
}

.nowrap{
    white-space: nowrap;
}

.manual-img__container{
    max-width: 115px;
}