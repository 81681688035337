.tt-menu.tt-open{
    background-color: white;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - var(--header-height) - 3rem);
}
.tt-dataset.tt-dataset-product{
    color: var(--color-primary);
}

.tt-selectable.tt-cursor{
    color: inherit;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-weight: 400;
}
.typeahead-heading{
    padding-left: 0;
}

.tt-input, .tt-menu, .twitter-typeahead{
    width: auto;
    flex-grow: 1;
}
.product-grid__category-link--active{
    text-transform: uppercase;
}