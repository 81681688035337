.tooltip,
.popover {
    font-family: inherit
}
.tooltip.bs-tooltip-top .arrow::before {
    border-top-color: #fff !important;
}
.tooltip-inner {
    color: #111;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 11px -2px rgba(17,17,17,0.41);
    box-shadow: 0px 3px 11px -2px rgba(17,17,17,0.41);
}