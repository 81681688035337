.bg-productinfo{
    font-size: 14px;
    line-height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;

    padding-left: 12px;
    padding-right: 13px;
    padding-top: 3px;

    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        font-size: 16px;
        padding-left: 21px;
    }
}

.bg-productinfo:last-child {
    margin-bottom: 0;
}
.bg-productinfo--new-product{
    background-color: var(--color-primary);
    color: #fff;
}
.bg-productinfo--new-color{
    background-color: var(--color-primary);
    color: #fff;
}
