.img-overlay{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.imageblock-2to1{
    padding: calc(52rem / 16) 0;
    @media screen and (max-width: 768px){
        padding: 18px 0;
    }
    @media screen and (min-width: 769px) and (max-width: 1199px){
        padding: 21px 0;
    }
    @media screen and (min-width: 1200px) and (max-width: 1250px){
        padding: 34px 0;
    }
    @media screen and (min-width: 1251px) and (max-width: 1450px){
        padding: 38px 0;
    }
}