.teaser-outfit__text{
    margin-bottom: calc(53rem / 16);
}

.teaser-outfit__text p{
    margin: 0;
}

.teaser-category__headline{
    margin-bottom: calc(32rem / 16);
}

.teaser-object.teaser-object--spacing{
    padding-top: calc(5rem / 16);
}