.page-content--home nav.breadcrumb-container{
    display: block !important;
}

@media screen and (max-width: 767px) {
    .page-content--home .hero-slider br {
        display: none;
    }
}

.page-content--home .pimcore_area_hero-slider{
    @media screen and (max-width: 767px) {
        margin-bottom: 60px;
    }
}