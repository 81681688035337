.story-hub__card-body{
    display: flex;
    flex-direction: column;
}

.story-hub__wysiwyg{
    flex-grow: 1;
}

.story-hub__title{
    display: inline-block;
    font-size: 19px;
    min-height: 44px;
    @supports (-moz-appearance:none) {
        min-height: 45.6px;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        min-height: 45.6px;
    }
}

.js-storyHubTeaser__content .load-container--new-loading-icon {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    margin-top: 0;

    transform: none;
}

.js-storyHubTeaser__content .load-container--new-loading-icon .load-container__icon {
    position: fixed;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    z-index: 1;
}

.js-storyHubTeaser__content .load-container--new-loading-icon + .load-container-bg {
    position: fixed;
}
