
.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    pointer-events: none;
}

.metabar{
    z-index: 0;
    height: var(--metabar-height);
    pointer-events: auto;
}


.header__nav{
    background-color: white;
    height: var(--header-height);
    z-index: 1;
    pointer-events: auto;
    border-bottom: 1px solid #DEDEDE;
    @media screen and (max-width: 1024px) {
        height: var(--header-height-mobile);
    }
}

.header__btn {
    padding: 0.5em;
    margin: 0.125em;
    color: var(--color-text-default);
    @media screen and (max-width: 1024px) {
        padding-top: 10px;
        margin:0;
        border:0;
    }
}
.header__logo{
    height: 40px;
    width: 55px;
    display: inline-block;
    flex-shrink: 0;
    @media screen and (min-width: 1200px) {
        height: 36px;
        width: 185px;
    }
}
.header__logo-additional{
    display: inline-block;
    position: relative;
    width: auto;
    margin-left:2rem;
}
.header__logo-additional-text{
    position: absolute;
    top:-.125rem;
    white-space: nowrap;
    display: block;
    height:.875rem;
    text-transform: uppercase;
    font-size: .625rem;
}
.header__logo-additional > img{
    display: inline-block;
    height: 19px;
    width:auto;
    margin-left:1.5rem;
    margin-top:.75rem;
}

#main-nav{
    flex-wrap: nowrap;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

#primaryNavigation{
    display: flex;
    justify-content: center;
}

.header__nav .nav-item{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
    text-align: center;
}

.header__nav .nav-item > a{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}


.nav-item.nav-item--decoration a.is-active{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
.nav-item.nav-item--decoration a.is-active:after {
    opacity: 1;
}
.nav-item.nav-item--decoration > a{
    text-align: center !important;
}
.header__navItem-text{
    transform: translateY(3px);
    font-size: 1rem;
}

.header__navItem-text .icon {
    display: none !important;
}


.header__searchDropdown.header__dropdown{
    padding-top: calc(32rem / 16);
    padding-bottom: calc(32rem / 16);

    @media screen and (min-width: 768px) {
        height: 180px;
    }
}

.header__dropdown{
    padding: .75em;
    padding-top: 2em;
    background-color: #fff;
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    display: none;
    @media screen and (max-width: 1024px) {
        padding: 0;
    }
}
.headerCart__items{
    min-height: 100px;
}


.cart-dropdown__form{
    padding-left: 1em;
    padding-right: 1em;
    overflow-y: auto;
}

.header__dropdown.show{
    display: flex;
    opacity: 1;
}

.header__dropdown .load-container__icon {
    display: block;
    max-width: 100%;
    height: auto;
}

.header__backdrop{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    transition-duration: .15s;
    transition-property: opacity;
}

.header__backdrop.fade{
    opacity: 0;
}
.header__backdrop.show{
    opacity: .5;
}


.header__submenu .nav-item > a{
    @media screen and (max-width: 767px) {
        font-family: var(--font-default);
    }
}

.nav-item > a.header__dropdown-subheading{
    width: 100%;
    font-family: var(--font-default);
    font-weight: 400;
    @media screen and (min-width: 1025px){
        padding: 0 15px;
        height: auto;
        align-items: flex-start;
        margin-bottom: 1rem;
        font-family: var(--font-default-bold);
        text-transform: uppercase;
        font-weight: 400;
    }
}
.header__dropdown-subheading.has-submenu{
    text-transform: uppercase;
}


.header__navbar{
    height: calc(100% + 1px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.header__navbar-mobile-meta{
    width: 100%;
    border-top: 1px solid var(--color-border-grey);
    justify-content: center;
    align-items: center;
    padding: 1rem;
}


.header__submenu{
    width: 100%;
    @media screen and (max-width: 1024px) {
        z-index: 1;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        height: auto;
        min-height: 100%;
        transition-duration: .33s;
        transition-property: transform;
        transform: translateX(110%);
        display: none;
        will-change: transform;
        opacity: 1;
    }
}
.header__submenu .nav-item a:hover,
.header__submenu .nav-item a:focus,
.header__submenu .nav-item a:active{
    @media screen and (min-width: 992px){
        color: #000;
    }
}
.header__submenu .nav-item a:not([href]):hover,
.header__submenu .nav-item a:not([href]):focus,
.header__submenu .nav-item a:not([href]):active{
@media screen and (min-width: 992px){
        color: inherit;
    }
}
.header__cartBtn{
    padding-right: 0;
    margin-right: 0;
}

.header__menu-hyphen{
    height: 100%;
    display: flex;
    padding: 0 9px;
    justify-content: center;
    align-items: center;
    transform: translateX(15px);
    @media screen and (max-width: 1024px) {
        display: none;
    }
}
.header__menu-hyphen:after{
    content: '';
    width: 1px;
    height: 30%;
    background-color: var(--color-border-grey);
}

.header__submenu-sub{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header__submenu-sub .nav-item a{
    margin-bottom: 0.375 rem;
    word-break: break-word;
    word-wrap: break-word;
    @media screen and (max-width: 1024px) {
        min-height: 64px; /* weird min-height issue*/
        margin-bottom: 0;
    }
}


.header__submenu.show{
    @media screen and (max-width: 1024px) {
        display: block !important;
        transform: translate(0);
    }
}

.header__submenu .nav-item{
    text-align: left ;
    height: auto;
    @media screen and (min-width: 1025px){
        margin-bottom: 0.5em;
    }
}


.header__submenu-heading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 15px;
}
.header__submenu-heading-back{
    transform: translateY(10px);
    z-index: 1;
    height: 35px;
    display: flex;
    align-items: center;
    color: var(--color-text-grey);
}
.header__submenu-heading-back .icon{
    position: relative;
    margin-right: 1em;
    font-size: 7px;
    bottom: 1px;
}
.header__submenu-heading-link{
    position: relative;
    min-height: 64px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    text-transform: uppercase;
}
.header__submenu-heading-link:after{
    position: absolute;
    content: '';
    top: 100%;
    left: 0;
    height: 2px;
    width: 60px;
    background-color: var(--color-primary);
}
.header__submenu-heading-category{
    display: block;
    font-size: 10px;
    color: var(--color-text-grey);
    text-transform: uppercase;
}

.header__image-textoverlay{
    position: absolute;
    top:50%;
    bottom: auto;
    left: 1rem;
    right: 1rem;
    transform: translateY(-50%);
    text-align: center;
}
.header__image-textoverlay--bottom{
    bottom: 0;
    left: 50%;
    right: auto;
    top: auto;
    transform: translateX(-50%);
    text-align: center;
}


.header__subnav-close{
    position: absolute;
    top: 0px;
    right: 15px;
    color: var(--color-text-grey);

    @media screen and (max-width: 767px){
        top: -25px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px){
        top: -50px;
    }
    @media screen and (min-width: 1025px){
        top: -60px;
    }
}

.searchbar-spacing{
    @media screen and (max-width: 767px){
        margin-top:  calc(30rem/16);
        margin-bottom:  calc(24rem/16);
    }
}

.tt-menu.tt-open{
    @media screen and (max-width: 767px){
        width: calc(100% + 72px);
    }
}

.header__opennav{
    padding-right: 0 !important;
}

.header__opennav .icon{
    width: 26px;
}

.header__opennav:not(.show) .icon-close{
    display: none
}

.header__opennav.show .icon-burger{
    display: none;
}

.header__nav .icon-poi{
    width: 18.13px;
}
.header__nav .icon-ortovox-search{
    width: 25.61px;
    @media screen and (max-width: 1024px) {
        font-size: 22px;
    }
}
.header__nav .icon-ortovox-user{
    width: 22.61px;
}
.header__nav .icon-ortovox-ware{
    width: 21.59px;
    @media screen and (max-width: 1024px) {
        font-size: 22px;
    }
}

/* custom css before pimcore x */
.header__submenu-sub .nav-item a, a.footer__link {
    hyphens: auto; -webkit-hyphens: auto;
    -webkit-hyphenate-limit-chars: auto 3;
    -webkit-hyphenate-limit-lines: 4;
    -ms-hyphens: auto;
    -ms-hyphenate-limit-chars: auto 3;
    -ms-hyphenate-limit-lines: 4;
    display: inline-block;
}


.header__submenu-sub .nav-item a,
.footer-collapse li.mb-2,
.footer-collapse div {
    line-height: 1.3;
    padding-bottom: 4px;
}

.header__logo-additional .img-fluid {
    display:none;
}

.header__logo-additional .header__logo-additional-text {
    font-size: 16px;
    top: -35px;
    color: #999;
    line-height: 18px;
    height: auto;
}

@media screen and (min-width: 1440px) {
    .header__submenu .container { padding-left:15px !important; }
}

@media screen and (min-width: 1025px) {
    .header__submenu .container {
        padding-left: 3px;
        padding-right: 0;
    }

    .header__submenu-sub .nav-item:not(.px-desktop-header-2.px-desktop-header-2), a.header__dropdown-subheading {
        padding-left: 0 !important;
    }

    li.nav-item.flex-column.col-12.col-desktop-header-8.p-desktop-header-0.position-initial.pr-desktop-header-2 a.header__dropdown-subheading {
        padding-left: 15px !important;
    }
}
