.mosaic-text{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.mosaic-arrow{
    position: absolute;
    color: white;
    font-size: calc(42rem / 16);
    z-index: 1;
}

.mosaic-arrow--first{
    right: -60px;
    top: 50%;
    transform: translateY(-50%);
}
.mosaic-arrow--first .icon{
    transform: rotate(180deg);
}

.mosaic-arrow--second{
    left: 50px;
    bottom: -55px;
}
.mosaic-arrow--second .icon{
    transform: rotate(270deg);
}

.mosaic-arrow--fourth{
    left: 50%;
    bottom: -55px;
    transform: translateX(-50%);
}
.mosaic-arrow--fourth .icon{
    transform: rotate(270deg);
}

.mosaic-arrow--fifth{
    left: 0;
    top: 40px;
    transform: translateX(-50%);
}

/*mosaic arrows*/

/*top*/
.grid-item-small .mosaic-arrow--top-left,
.grid-item-big .mosaic-arrow--top-left{
    left: 25%;
    top: -30px;
    transform: translateX(-50%);
}
.grid-item-small .mosaic-arrow--top-center,
.grid-item-big .mosaic-arrow--top-center{
    left: 50%;
    top: -30px;
    transform: translateX(-50%);
}
.grid-item-small .mosaic-arrow--top-right,
.grid-item-big .mosaic-arrow--top-right{
    left: 75%;
    top: -30px;
    transform: translateX(-50%);
}

/*bottom*/
.grid-item-small .mosaic-arrow--bottom-left,
.grid-item-big .mosaic-arrow--bottom-left{
    left: 25%;
    bottom: -30px;
    transform: translateX(-50%);
}
.grid-item-small .mosaic-arrow--bottom-center,
.grid-item-big .mosaic-arrow--bottom-center{
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%);
}
.grid-item-small .mosaic-arrow--bottom-right,
.grid-item-big .mosaic-arrow--bottom-right{
    left: 75%;
    bottom: -30px;
    transform: translateX(-50%);
}

/*left*/
.grid-item-small .mosaic-arrow--left-top{
    left: -60px;
    top: 25%;
    transform: translateY(-50%);
}
.grid-item-small .mosaic-arrow--left-center{
    left: -60px;
    top: 50%;
    transform: translateY(-50%);
}
.grid-item-small .mosaic-arrow--left-bottom{
    left: -60px;
    top: 75%;
    transform: translateY(-50%);
}
.grid-item-big .mosaic-arrow--left-top{
    left: 0;
    top: 40px;
    transform: translateX(-50%)translateX(-8px);
}
.grid-item-big .mosaic-arrow--left-center{
    left: 0;
    top: 50%;
    transform: translateX(-50%)translateX(-8px)translateY(-50%);
}
.grid-item-big .mosaic-arrow--left-bottom{
    left: 0;
    bottom: 40px;
    transform: translateX(-50%)translateX(-8px);
}


/*right*/
.grid-item-small .mosaic-arrow--right-top{
    right: -60px;
    top: 25%;
    transform: translateY(-50%)translateX(8px);
}
.grid-item-small .mosaic-arrow--right-center{
    right: -60px;
    top: 50%;
    transform: translateY(-50%)translateX(8px);
}
.grid-item-small .mosaic-arrow--right-bottom{
    right: -60px;
    top: 75%;
    transform: translateY(-50%)translateX(8px);
}
.grid-item-big .mosaic-arrow--right-top{
    right: 0;
    top: 40px;
    transform: translateX(50%)translateX(8px)translateX(-8px);
}
.grid-item-big .mosaic-arrow--right-center{
    right: 0;
    bottom: 50%;
    transform: translateX(50%)translateX(8px)translateX(-8px)translateY(50%);
}
.grid-item-big .mosaic-arrow--right-bottom{
    right: 0;
    bottom: 40px;
    transform: translateX(50%)translateX(8px)translateX(-8px);
}

.mosaic-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 8px;
    grid-column-gap: 1em;
    grid-auto-rows: minmax(1px,auto);

    @media screen and (max-width: 767px){
        grid-template-columns: 1fr;
    }
}

.grid-item-small{
    grid-column: 1 span;
    grid-row: 2 span;
}

.grid-item-big{
    grid-column: 1 span;
    grid-row: 3 span;
}

.grid-1{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;

    @media screen and (max-width: 767px){
        grid-column-start: auto;
        grid-column-end: auto;
        grid-row-start: auto;
        grid-row-end: auto;
    }
}

.grid-2{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;

    @media screen and (max-width: 767px) {
        grid-column-start: auto;
        grid-column-end: auto;
        grid-row-start: auto;
        grid-row-end: auto;
    }
}

.grid-3{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 7;

    @media screen and (max-width: 767px) {
        grid-column-start: auto;
        grid-column-end: auto;
        grid-row-start: auto;
        grid-row-end: auto;
    }
}

.grid-4{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 5;

    @media screen and (max-width: 767px) {
        grid-column-start: auto;
        grid-column-end: auto;
        grid-row-start: auto;
        grid-row-end: auto;
    }
}

.grid-5{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 7;

    @media screen and (max-width: 767px) {
        grid-column-start: auto;
        grid-column-end: auto;
        grid-row-start: auto;
        grid-row-end: auto;
    }
}