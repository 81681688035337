.autoplay-video-controls {
    --autoplayVideoButtonSize: 40px;
    --autoplayProgressBarStrokeWidth: 2;
}

.autoplay-video-controls__controls {
    position: absolute;
    bottom: 15px;
    right: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--autoplayVideoButtonSize);
    height: var(--autoplayVideoButtonSize);

    z-index: 1;
}

.autoplay-video-controls {
    position: relative;
}

.autoplay-video-controls--has-background {
    background: black;
}

.autoplay-video-controls--has-background.autoplay-video-controls--has-background-gradient {
    background: transparent;
}

.autoplay-video-controls--has-background-gradient::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 20%;

    background: linear-gradient(rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.65) 100%);
    z-index: 1;
}

.autoplay-video-controls__control {
    width: 100%;
    height: 100%;

    font-size: var(--autoplayVideoButtonSize);
    color: rgb(255 255 255 / 75%);

    padding: 0;
    border: 0;

    appearance: none;
    background: transparent;
}

.autoplay-video-controls--has-background-gradient .autoplay-video-controls__controls {
    z-index: 2;
}

.autoplay-video-controls__progress-bar-wrapper {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 1;
    pointer-events: none;
}

.autoplay-video-controls__progress-bar {
    stroke: white;
    stroke-width: var(--autoplayProgressBarStrokeWidth);
    fill: transparent;

    transition: stroke-dashoffset 0.66666s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.js-autoplay-video-controls__control--pause,
.js-autoplay-video-controls--video-paused .js-autoplay-video-controls__control--play {
    display: flex;
}

.js-autoplay-video-controls__control--play,
.js-autoplay-video-controls--video-paused .js-autoplay-video-controls__control--pause {
    display: none;
}
