.donation-modal {
    @media screen and (max-width: 767px) {
        font-size: calc(14rem / 16);
    }
}
.donation-modal__body {
    padding: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16);
    }
}
.donation-modal__option {
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(19rem / 16);
    }
}
