.donation-modal__title,
.donation-modal__wysiwyg .donation-modal__title {
    font-size: calc(33rem / 16);
    line-height: 1.2;
    margin-bottom: .5rem;

    @media screen and (max-width: 767px) {
        font-size: calc(22rem / 16);
    }
}

.donation-modal__title:after,
.donation-modal__wysiwyg .donation-modal__title:after {
    display: none;
}

.donation-modal__wysiwyg p:not(:last-child) {
    margin-bottom: 1rem;
}