.ovx-m4_headline {
    padding-bottom: 20px;
    position: relative;
    @media screen and (max-width: 767px) {
        padding-bottom: 22px;
    }
}

.ovx-m4_headline:before {
    background: #878787;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 70px;
}

.ovx-m4_body {
    line-height: 1.244 !important;
    text-transform: none !important;

    @media screen and (max-width: 767px) {
        margin-top: 22px !important;
    }
}
