.border-sm-0 {
    @media screen and (max-width: 767px) {
        border: 0;
    }
}

.border-md-0 {
    @media screen and (min-width: 768px) {
        border: 0;
    }
}

.border-lg-0 {
    @media screen and (min-width: 1200px) {
        border: 0;
    }
}


.border-sm-0--force {
    @media screen and (max-width: 767px) {
        border: 0 !important;
    }
}

.border-md-0--force {
    @media screen and (min-width: 768px) {
        border: 0 !important;
    }
}

.border-lg-0--force {
    @media screen and (min-width: 1200px) {
        border: 0 !important;
    }
}
