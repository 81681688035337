.js-imageSlider .slick-slide div{
    line-height: 0;
}
.slick-arrow.slick-disabled {
    opacity: .5;
    visibility: visible;
    display: flex !important; /*override display from public/static/css/style/slider.css*/
}

.pds__product-slider-main .slick-arrow.slick-disabled {
    @media screen and (min-width: 768px) {
        display: none !important;
    }
}

.slick-arrow {
    color: var(--color-text-grey);
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: 767px) {
        font-size: .65rem;
    }
}
.pds__product-slider-main .slick-arrow {
    @media screen and (min-width: 768px) {
        display: none !important;
    }
}
.bg-grey .slick-arrow {
    color: var(--color-text-grey-on-grey);
}

.slick-slider--same-height .slick-track {
    display: flex;
}
.slick-slider--same-height .slick-slide{
    height: auto;
    display: flex;
    flex-direction: column;
}
/*make sure all slides are the same height*/
.slick-slider--same-height .slick-slide > div,
.slick-slider--same-height .slick-slide > div > div,
.slick-slider--same-height .slick-slide > div > div > div {
    display: flex !important; /* override inline style */
    flex-direction: column;
    flex: auto;
}

.pds__product-slider-main .slick-track,
.js-pds__product-slider-main.slick-slider:not(.slick-initialized) {
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: auto !important;

        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: -20px;

        transform: none !important;
    }
}

.pds__product-slider-main .slick-track:before,
.pds__product-slider-main .slick-track:after {
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.pds__product-slider-main .slick-slide,
.js-pds__product-slider-main:not(.slick-initialized) .pds__product-slider__item-inner {
    @media screen and (min-width: 768px) {
        flex: 0 0 50%;
        max-width: 50%;

        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
    }
}


.js-pds__product-slider-main:not(.slick-initialized) .pds__product-slider__item-inner {
    @media screen and (min-width: 768px) {
        background: #fff;
        visibility: visible;
        display: inline-block;
    }
}

.pds__product-slider-main .slick-slide > div > div {
    display: block !important;
}

.pds__product-slider--slide-2-md-order-4 .slick-slide {
    @media screen and (min-width: 768px) {
        order: 5;
    }
}

.pds__product-slider--slide-2-md-order-4 .slick-slide:nth-child(1) {
    @media screen and (min-width: 768px) {
        order: 1;
    }
}

.pds__product-slider--slide-2-md-order-4 .slick-slide:nth-child(2) {
    @media screen and (min-width: 768px) {
        order: 4;
    }
}

.pds__product-slider--slide-2-md-order-4 .slick-slide:nth-child(3) {
    @media screen and (min-width: 768px) {
        order: 2;
    }
}

.pds__product-slider--slide-2-md-order-4 .slick-slide:nth-child(4) {
    @media screen and (min-width: 768px) {
        order: 3;
    }
}

.pds__product-slider .video-js__textcontainer--toggle-play-pause-btns .video-js__pausebtn,
.pds__product-slider .video-js__textcontainer--toggle-play-pause-btns .video-js__playbtn {
    width: 100%;
    height: 100%;

    align-items: flex-start;
    justify-content: flex-end;

    padding: calc(20rem / 16);

    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .3));

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16);
    }
}

.pds__product-slider .video-js__textcontainer--toggle-play-pause-btns .video-js__playbtn:before,
.pds__product-slider .video-js__textcontainer--toggle-play-pause-btns .video-js__pausebtn:before {
    font-size: calc(24rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
    }
}

.pds__product-slider .pimcore_editable_video + .video-js__textcontainer--toggle-play-pause-btns .video-js__pausebtn,
.pds__product-slider .pimcore_editable_video.is-playing + .video-js__textcontainer .video-js__playbtn {
    display: none !important;
}

.pds__product-slider .pimcore_editable_video + .video-js__textcontainer--toggle-play-pause-btns .video-js__playbtn,
.pds__product-slider .pimcore_editable_video.is-playing + .video-js__textcontainer .video-js__pausebtn {
    display: flex !important;
}

.pds__product-slider .pimcore_editable_video .embed-responsive-item {
    transform: scale(1.05);
}
