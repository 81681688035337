.expandable__collapse {
    position: relative;
}
.expandable__collapse:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
    transition-duration: .33s;
    transition-property: opacity;
    pointer-events: none;
}
.expandable__collapse--grey:after {
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), var(--color-background-grey));
}
.expandable__collapse--small-gradient:after {
    height: 50px;
}
.expandable__collapse.show:after {
    opacity: 0;
}
.expandable__collapse.collapse:not(.show) {
    display: block !important;
    height: 0 !important;
    overflow: hidden;
}
.expandable__toggle {
    border: none;
    background: none;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    color: var(--color-text-grey);
    outline: none;
}
.expandable__toggle:focus,
.expandable__toggle:active {
    outline: none;
    box-shadow: none;
}
.expandable__toggle:hover,
.expandable__toggle:active {
    color: var(--color-text-grey-on-grey);
}
.expandable__toggle:not(.collapsed) .expandable__toggle-collapse,
.expandable__toggle.collapsed .expandable__toggle-show {
    display: inline-block;
}
.expandable__toggle.collapsed .expandable__toggle-collapse,
.expandable__toggle:not(.collapsed) .expandable__toggle-show {
    display: none;
}
.expandable__toggle-icon {
    font-size: 0.4rem;
    vertical-align: 0.4em;
    margin-left: .5rem;
    transition: transform 120ms ease-out;
}
.expandable__toggle.collapsed .expandable__toggle-icon {
    transform: rotate(180deg);
}